<template>
	<div class="login_box">
		<div class="tap_box">
			<div class="tap_item" :class="{ active_tab: activeTap === 0 }" @click="changeTap(0)">账号登录</div>
			<!-- <div class="tap_item" :class="{ active_tab: activeTap === 1 }" @click="changeTap(1)">短信登录</div> -->
		</div>
		<div class="input_box" v-if="activeTap === 0">
			<el-input class="input_cont" placeholder="用户名" v-model="username" clearable></el-input>
			<el-input class="input_cont" placeholder="密码" v-model="password" show-password clearable @keyup.enter.native="onclickLogin"></el-input>
			<el-row class="input_cont codeimg_box" :gutter="10">
				<el-col :span="16">
					<el-input  placeholder="验证码" v-model="imgCode" clearable></el-input>
				</el-col>
				<el-col :span="8">
					<img class="code_img" :src="src" alt="" @click="changeCodeImg">
				</el-col>
			</el-row>
		</div>
		<!-- <div class="input_box" v-else>
			<el-input class="input_cont" placeholder="联系人手机号" v-model="phoneNumber" clearable></el-input>
			<el-input class="input_cont" placeholder="验证码" v-model="verifiyCode" @keyup.enter.native="onclickLogin">
				<template slot="append">
					<div class="get_code" @click="getCode">
						<span v-if="count != 60">重新获取 {{ count }} S</span>
						<span v-else class="active_get_code">获取验证码</span>
					</div>
				</template>
			</el-input>
		</div> -->
		<div class="handle_box">
			<div>
				<el-checkbox size="medium" v-model="rememberPwd" @change="rememberPwdChange">下次自动登录</el-checkbox>
			</div>
			<div class="right">
				<span class="left_text" @click="navigatorTo('/forgrtPwd')">忘记密码</span>
				<span>|</span>
				<span class="right_text" @click="navigatorTo('/registerUser')">注册</span>
			</div>
		</div>
		<div class="login_btn" @click="onclickLogin">登录</div>
		<div class="bottom_to_login">
			<span class="login_text" @click="navigatorBack">不登录了？返回首页</span>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
	data() {
		return {
			activeTap: 0,
			username: '',
			password: '',
			imgCode:'',
			phoneNumber:'',
			verifiyCode: '',
			count: 60,
			timeId:null,
			rememberPwd:true,	//记住密码
			src:'https://www.offshorecloud.org.cn/codia/company/getVerifyCodeImage'
		};
	},
	watch:{
		accountLoginResult(){
			if(this.accountLoginResult.success){
				this.$message({
					showClose: true,
					message:'登录成功',
					type: 'success'
				});
				setTimeout(()=>{
					this.navigatorTo('/homePage');
				},1000)
			}else{
				this.$message({
					showClose: true,
					message:this.accountLoginResult.message,
					type: 'error'
				});
			}
		},
		loginSMSCodeResult(){
			if(this.loginSMSCodeResult.success){
				this.$message({
					showClose: true,
					message:'获取验证码成功',
					type: 'success'
				});
				this.count = 59;
				this.timeId = setInterval(()=>{
					this.count -= 1;
					if(this.count == 0){
						this.count = 60;
						clearInterval(this.timeId);
					}
				},1000)
			}else{
				this.$message({
					showClose: true,
					message:this.loginSMSCodeResult.message,
					type: 'error'
				});
			}
		},
	},
	computed: {
		...mapState({
			accountLoginResult: state => state.AboutLogin.accountLoginResult,
			loginSMSCodeResult: state => state.AboutLogin.loginSMSCodeResult,
		}),
	},
	methods: {
		...mapMutations({
			setRememberPassword:'AboutLogin/setRememberPassword'
		}),
		...mapActions({
			accountLogin:'AboutLogin/accountLogin',
			loginSMSCode:'AboutLogin/loginSMSCode',
			phoneLogin:'AboutLogin/phoneLogin',
			SMSCodeImage:'AboutLogin/SMSCodeImage',
		}),
		navigatorTo(path){
			this.$router.replace(path);
		},
		navigatorBack(){
			this.$router.go(-1);
		},
		changeCodeImg(){
			this.src = 'https://www.offshorecloud.org.cn/codia/company/getVerifyCodeImage?'+Math.random()
		},
		changeTap(index) {
			this.activeTap = index;
		},
		getCode(){
			let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
			if (!reg.test(this.phoneNumber)) {
				this.$message({
					showClose: true,
					message: '联系人手机号不合规范',
					type: 'error'
				});
			  return
			}
			if(this.count != 60){
				return
			}
			let params = {
				phoneNumber:this.phoneNumber,
			}
			this.loginSMSCode(params);
		},
		rememberPwdChange(){
			// console.log(this.rememberPwd)
			this.setRememberPassword(this.rememberPwd);
		},
		onclickLogin(){	
			if(this.activeTap === 0){
				console.log("账户密码登录");
				if(!this.username){
					this.$message({
						showClose: true,
						message: '用户名不能为空',
						type: 'error'
					});
					return
				}
				if(!this.password){
					this.$message({
						showClose: true,
						message: '密码不能为空',
						type: 'error'
					});
					return
				}
				if(!this.password){
					this.$message({
						showClose: true,
						message: '验证码不能为空',
						type: 'error'
					});
					return
				}
				let params = {
					authenticationString:this.$aesEncrypt(JSON.stringify({
						username:this.username,
						password:this.password,
						imgCode:this.imgCode,
					}))
				}
				this.accountLogin(params);
			}else{
				console.log("短信登录");
				let params = {
					phoneNumber:this.phoneNumber,
					verifiyCode:this.verifiyCode
				}
				this.phoneLogin(params);
			}
		},
	},
	mounted() {}
};
</script>

<style lang="scss" scoped>
.login_box {
	width: 100%;
	height: auto;
	.tap_box {
		width: 100%;
		height: 60px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-around;
		.tap_item {
			width: auto;
			height: 38px;
			font-size: 20px;
			font-weight: 600;
			box-sizing: border-box;
			color: #999;
			cursor: pointer;
		}
		.active_tab {
			border-bottom: 3px solid $theam-bgcolor;
			color: $theam-bgcolor;
		}
	}
	.input_box {
		width: 100%;
		height: auto;
		.input_cont {
			margin-top: 20px;
			.get_code{
				width: 110px;
				height: 38px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.active_get_code{
				color: $theam-color;
			}
		}
		.code_box {
			width: 100%;
			height: 40px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.codeimg_box{
			display: flex;
			align-items: center;
		}
		.code_img{
			width: 100%;
			height: 38px;
		}
	}
	.handle_box{
		width: 100%;
		height: 100px;
		box-sizing: border-box;
		padding-top: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.right{
			font-size: 14px;
			color: #999;
		}
		.el-checkbox{
			color: #999;
		}
		.left_text{
			padding-right: 10px;
			cursor: pointer;
		}
		.right_text{
			padding-left: 10px;
			cursor: pointer;
		}
	}
	.login_btn{
		width: 100%;
		height: 44px;
		border-radius: 44px;
		color: #fff;
		background-color: $theam-bgcolor;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.bottom_to_login{
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: #999;
		font-size: 14px;
		.login_text{
			cursor: pointer;
		}
	}
}
</style>
