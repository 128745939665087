<template>
	<div class="login_box">
		<img class="bg_img"  :src="imgUrl+'/unLoginPages/login/bg.png'" >

		<img class="logo_img" src="../../../assets/unLoginPages/login/logo.png" >
		<div class="login_cont_box">
			<div class="cont">
				<router-view/>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex'
	export default {
		data(){
			return {
				
			}
		},
		computed: {
		...mapState({
			imgUrl: state => state.imgUrl,

		}),
	},
		methods:{
			
		},
		mounted() {
			
		}
		
	}
</script>

<style lang="scss" scoped>
	.login_box{
		width: 100%;
		height: 100vh;
		position: relative;
		.bg_img{
			width: 100%;
			height: 100%;
		}
		.logo_img{
			width: 362px;
			height: 42px;
			position: absolute;
			top: 80px;
			left: 80px;
			z-index: 3;
		}
		.login_cont_box{
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			padding: 80px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			.cont{
				width: 460px;
				min-height: 460px;
				border-radius: 20px;
				background-color: #fff;
				box-sizing: border-box;
				padding: 40px 40px 60px 40px;
			}
		}
	}
</style>
