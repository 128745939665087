<template>
	<div class="register_box">
		<div class="tap_box">
			<div class="tap_item">账户注册</div>
		</div>
		<div class="input_box">
			<el-input class="input_cont" placeholder="用户名" v-model="username" clearable></el-input>
			<el-input class="input_cont" placeholder="密码" v-model="password" show-password clearable></el-input>
			<el-input class="input_cont" placeholder="确认密码" v-model="passwordAgin" show-password clearable></el-input>
			<el-input class="input_cont" placeholder="联系电话" v-model="phoneNumber" clearable></el-input>
			<el-input class="input_cont" placeholder="绑定邮箱号" v-model="email" clearable></el-input>
			<el-input class="input_cont" placeholder="验证码" v-model="verifiyCode">
				<template slot="append">
					<div class="get_code" @click="getCode">
						<span v-if="count != 120">重新获取 {{ count }} S</span>
						<span v-else class="active_get_code">获取验证码</span>
					</div>
				</template>
			</el-input>
		</div>
		<div class="handle_box">
			<el-checkbox size="medium" v-model="agree" @change="agreeChange">
				阅读并接受
			</el-checkbox>
			<div class="agreement_box">
				<span class="agreement_text" @click="showUserAgreement = true">《CODIA用户协议》</span>
				及
				<span class="agreement_text" @click="showPrivacyAgreement = true">《CODIA隐私协议》</span>
			</div>
		</div>
		<div class="login_btn" @click="onclickRegister">注册</div>
		<div class="bottom_to_login">
			<span class="login_text" @click="navigatorTo('/login')">已有账户？前往登录</span>
		</div>
		<!-- 用户协议 -->
		<el-dialog
		  title="用户协议"
		  :visible.sync="showUserAgreement"
		  top="5vh"
		  width="1200px"
			append-to-body>
		  <div class="dialog_content_box">
		    <UserAgreement v-if="showUserAgreement"></UserAgreement>
		  </div>
		</el-dialog>
		<!-- 隐私协议 -->
		<el-dialog
		  title="隐私协议"
		  :visible.sync="showPrivacyAgreement"
		  top="5vh"
		  width="1200px"
			append-to-body>
		  <div class="dialog_content_box">
		    <PrivacyAgreement v-if="showPrivacyAgreement"></PrivacyAgreement>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UserAgreement from './userAgreement.vue'
import PrivacyAgreement from './privacyAgreement.vue'
export default {
	components:{
		UserAgreement,
		PrivacyAgreement
	},
	data() {
		return {
			username: '',
			password: '',
			passwordAgin:'',
			email:'',
			phoneNumber:'',
			verifiyCode: '',
			count: 120, //下次获取验证码倒计时
			timeId:null,
			agree:false,	
			showUserAgreement:false,
			showPrivacyAgreement:false,
		};
	},
	watch:{
		registerSMSCodeResult(){
			if(this.registerSMSCodeResult.success){
				this.$message({
					showClose: true,
					message:'获取验证码成功',
					type: 'success'
				});
				this.count = 119;
				this.timeId = setInterval(()=>{
					this.count -= 1;
					if(this.count == 0){
						this.count = 120;
						clearInterval(this.timeId);
					}
				},1000)
			}else{
				this.$message({
					showClose: true,
					message:this.registerSMSCodeResult.message,
					type: 'error'
				});
			}
		},
		registerResult(){
			if(this.registerResult.success){
				this.$message({
					showClose: true,
					message:'注册成功',
					type: 'success'
				});
				setTimeout(()=>{
					this.navigatorTo('/login');
				},1500)
			}else{
				this.$message({
					showClose: true,
					message:this.registerResult.message,
					type: 'error'
				});
			}
		},
		
	},
	computed: {
		...mapState({
			registerSMSCodeResult: state => state.AboutLogin.registerSMSCodeResult,
			registerResult: state => state.AboutLogin.registerResult,
		}),
	},
	methods: {
		...mapActions({
			register:'AboutLogin/register',
			registerSMSCode:'AboutLogin/registerSMSCode',
		}),
		navigatorTo(path){
			this.$router.replace(path);
		},
		getCode(){
			// let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
			// if (!reg.test(this.email)) {
			// 	this.$message({
			// 		showClose: true,
			// 		message: '联系人手机号不合规范',
			// 		type: 'error'
			// 	});
			//   return
			// }
			let reg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
			if (!reg.test(this.email)) {
				this.$message({
					showClose: true,
					message: '邮箱号不合规范',
					type: 'error'
				});
			  return
			}
			if(this.count != 120){
				return
			}
			
			let params = {
				email:this.email,
			}
			this.registerSMSCode(params);
		},
		agreeChange(){
			// console.log(this.agree)
		},
		onclickRegister(){	//注册
			if(!this.username){
				this.$message({
					showClose: true,
					message: '用户名不能为空',
					type: 'error'
				});
				return
			}
			if(!this.password){
				this.$message({
					showClose: true,
					message: '密码不能为空',
					type: 'error'
				});
				return
			}
			if(this.password != this.passwordAgin){
				this.$message({
					showClose: true,
					message: '两次密码输入不一致',
					type: 'error'
				});
				return
			}
			if(!this.phoneNumber){
				this.$message({
					showClose: true,
					message: '联系电话不能为空',
					type: 'error'
				});
				return
			}
			if(!this.email){
				this.$message({
					showClose: true,
					message: '邮箱号不能为空',
					type: 'error'
				});
				return
			}
			if(!this.verifiyCode){
				this.$message({
					showClose: true,
					message: '验证码不能为空',
					type: 'error'
				});
				return
			}
			if(!this.agree){
				this.$message({
					showClose: true,
					message: '请勾选用户协议和隐私协议',
					type: 'error'
				});
				return
			}
			let params = {
				authenticationString:this.$aesEncrypt(JSON.stringify({
					username:this.username,
					password:this.password,
				})),
				phoneNumber:this.phoneNumber,
				email:this.email,
				verifiyCode:this.verifiyCode,
			}
			this.register(params);
		},
	},
	mounted() {
		
	}
};
</script>

<style lang="scss" scoped>
.register_box {
	width: 100%;
	height: auto;
	.tap_box {
		width: 100%;
		height: 60px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content:center;
		.tap_item {
			width: auto;
			height: 38px;
			font-size: 20px;
			font-weight: 600;
			box-sizing: border-box;
			border-bottom: 3px solid $theam-bgcolor;
			color: $theam-bgcolor;
		}
	}
	.input_box {
		width: 100%;
		height: auto;
		.input_cont {
			margin-top: 20px;
			.get_code{
				width: 110px;
				height: 38px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.active_get_code{
				color: $theam-color;
			}
		}
		.code_box {
			width: 100%;
			height: 40px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}
	.handle_box{
		width: 100%;
		height: 100px;
		box-sizing: border-box;
		padding-top: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		.el-checkbox{
			color: #999;
		}
		.agreement_box{
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding-bottom: 2px;
		}
		.agreement_text{
			cursor: pointer;
			color: $theam-color;
			font-size: 14px;
		}
	}
	.login_btn{
		width: 100%;
		height: 44px;
		border-radius: 44px;
		color: #fff;
		background-color: $theam-bgcolor;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.bottom_to_login{
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: #999;
		font-size: 14px;
		.login_text{
			cursor: pointer;
		}
	}
}
</style>
