<template>
	<div class="randomCode_box">
		<div class="left_container">
			<div class="textarea_box">
				<el-input
				  type="textarea"
				  :rows="18"
				  placeholder="请输入文本内容"
				  v-model="textVal">
				</el-input>
			</div>
			<div class="btn_box">
				 <el-button type="primary" @click='createQrcode'>立即生成二维码</el-button>
			</div>
		</div>
		<div class="right_box">
			<div class="right_code_box" v-if="showCodeBox">
				<div id="randowCode" ref="randowCode" v-if="showCode"></div>
				<div class="text" v-else>此处预览二维码</div>
			</div>
			<div class="code_btn_box" v-if="showCode">
				<el-button type="info" plain @click="downCodeImg">下载二维码</el-button>
				<el-button type="success" plain @click="handlePrint">打印二维码</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'; // 引入qrcode
	import { getLodop } from '../../../common/lib/LodopFuncs.js';
	import {mapActions, mapState} from 'vuex'
	export default {
		data(){
			return {
				textVal:'',
				showCode:false,
				showCodeBox:true,
			}
		},
		computed:{
			
		},
		methods:{
			createQrcode(){	//生成二维码
				if(!this.textVal){
					this.$message({
						showClose: true,
						message:'请输入文本内容',
						type: 'error'
					});
					return
				}
				this.showCode = true;
				this.showCodeBox = false;
				this.$nextTick(()=>{
					this.showCodeBox = true;
					this.$nextTick(()=>{
						new QRCode('randowCode', {
						  width: 300,
						  height: 300,
						  text: this.textVal, // 二维码地址
						});
					})
				})
			},
			downCodeImg(){
				let myCanvas = document.getElementById("randowCode").getElementsByTagName("canvas");
				let a = document.createElement("a");
	      a.href = myCanvas[0].toDataURL("image/png");
	      a.download = "海工随机二维码";
	      a.click();
			},
			handlePrint(){	//打印单个二维码
				let text = "123"
				let LODOP = getLodop();
				LODOP.PRINT_INIT("");
				LODOP.SET_PRINT_PAGESIZE(1, 440, 440,"");
				LODOP.ADD_PRINT_BARCODE(5, 5, 180, 180, "QRCode", `${text}`);
				LODOP.PREVIEW(); //预览(预览打印无脚标)
				// LODOP.PRINT();  //打印
			},
		},
		mounted() {
			
		}
	}
</script>

<style lang="scss" scoped>
	.randomCode_box{
		margin: 20px 0 40px 0;
		width: 100%;
		height: auto;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		.left_container{
			width: 50%;
			padding: 20px;
			border-radius: 10px;
			background-color: #fff;
			border: 1px solid #eee;
			.textarea_box{
				padding: 10px 0;
			}
			.btn_box{
				width: 100%;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.right_box{
			width: 360px;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.right_code_box{
				width: 360px;
				height: 360px;
				background-color: #fff;
				border-radius: 10px;
				box-sizing: border-box;
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #eee;
				.text{
					font-size: 30px;
					color: #999;
				}
			}
			.code_btn_box{
				width: 100%;
				height: 60px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
