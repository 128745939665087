<!-- 联盟章程  -->
<template>
  <div class="con2_page">
    <div class="titleBox">
      <p class="font_24">
        <strong>中国深远海海洋工程装备技术产业联盟章程</strong>
      </p>
    </div>
    <div class="chapter_01">
      <p><strong>第一章 总 则</strong></p>
      <div class="chapter_content">
        <p>
          <strong>第一条</strong>&nbsp;
          联盟中文名称：中国深远海海洋工程装备技术产业联盟，简称：中国海工联盟；
          英文名称：China Offshore (Deepsea) Industry Alliance, 缩写：CODIA。
        </p>
        <p class="text_indent">中国深远海海洋工程装备技术产业联盟是一个在海洋工程领域具有战略性、前瞻性、以发展1500米以上深远海海洋工程装备为目标，由积极投身于深远海海洋工程事业，从事深远海海洋工程产业研究开发、总装制造、设备制造、应用服务等，能够代表我国深远海海洋工程装备各领域发展水平的企事业单位、大专院校、科研机构、用户单位、认证机构和金融机构及其他相关机构等自愿组成的以联合创新和产业协作为目的的全国性联盟组织。</p>
        <p><strong>第二条</strong>&nbsp;本联盟坚持创新、合作、发展、共赢的原则，联盟及其成员遵守中华人民共和国法律、法规，接受国家工业和信息化部的行业管理和业务指导。 </p>
      </div>
    </div>
    <!-- 第二章 -->
    <div class="chapter_02">
      <p><strong>第二章 宗旨、目标、路径及业务范围</strong></p>
      <div class="chapter_content">
        <p><strong>第三条</strong>&nbsp;
          宗旨：以国家产业发展战略和产业政策为引导，以企业为主体，以市场为导向，优化资源配置，推动强强联合，改善产业发展环境；促进产学研用全产业链融合，推动深海海工装备技术进步和产业化，促进我国深远海海洋工程产业的健康发展；更广泛地参与国际竞争，打造中国深海海工装备中国形象和中国品牌。
        </p>
        <p><strong>第四条</strong>&nbsp;目标</p>
        <ol class="ol">
            <li>加强行业自律：联盟内企业相互支持，加强合作，规范自律，培育市场，突破瓶颈，做优做强；</li>
            <li>搭建沟通与协作的平台：构建用户、企业、科研院所、金融机构资源信息共享、合作交流的平台，加强与相关政府部门的沟通与协调，反映行业诉求，提出政策建议；</li>
            <li>加强产业链协同创新：瞄准深海海洋工程装备设计制造关键共性技术、重大战略性产品研发、标准规范制定等方面，开展产学研用相关单位联合攻关，加快突破产业技术瓶颈；</li>
            <li>完善配套产业链：加强海工装备关键系统和设备的研制，以总装建造带动关键系统和配套设备的发展，推动首台套设备的示范应用。</li>
            <li>联合参与国际竞争：联合开展深海装备制造的国际交流、合作与对话，积极融入全球产业链，打造中国深海海洋工程装备国际品牌，形成良好的产业竞争合作发展环境。</li>
        </ol>
        <p><strong>第五条</strong>&nbsp;路径</p>
        <ol class="ol">
            <li>联盟内成员单位研究共同抵御当前市场的措施，制定行业自律准则，发布行业宣言，优化市场竞争秩序。</li>
            <li>沟通行业信息，反应行业诉求，构筑行业企业间、上下游产业链及与政府间的对话与交流平台。</li>
            <li>承担国家重大科研项目，组织产学研用联合攻关，加快深远海工程装备领域的关键技术、共性技术的突破，促进重大科技成果转化，增强自主创新能力；探讨形成团体标准。</li>
            <li>与海洋工程装备关键系统和设备企业联合攻关，协同创新，依托国家重大科研专项和示范工程等，促进配套产品装船应用。</li>
            <li>探索联合收购国外研发设计机构、合资成立研究院，以及互相参股、换股、总包、分包等多种方式，形成利益共同体，共同抵御市场风险，加快技术能力和水平的提升。</li>
            <li>通过召开年会、研讨会、论坛等多种形式，加强国际国内行业间、上下游产业链之间的对话与交流，促进国际合作，塑造中国品牌和中国企业形象，扩大中国深海海工装备的影响力，营造我国海工装备产业参与国际竞争与发展的良好市场环境。</li>
        </ol>
        <p><strong>第六条</strong>&nbsp;业务范围</p>
        <ol class="ol">
            <li>贯彻落实国家相关的产业政策； </li>
            <li>研究联盟发展方向，制定联盟发展规划，通过多种渠道、不同方式，促进联盟融入国家“一带一路”等发展战略；</li>
            <li>收集、统计行业相关信息，研究行业运行态势，发布行业运行报告，提供相关资讯服务，逐步发展成为行业智库； </li>
            <li>向政府有关部门提出政策建议，反应行业诉求，搭建企业与政府间的沟通桥梁；</li>
            <li>组织重大关键共性技术研发，产学研用联合创新，总装带动配套共同发展，突破产业瓶颈，促进产业化；</li>
            <li>组织制定相关行业行规、规范、体系、标准等，推动中国技术标准与国际标准接轨；</li>
            <li>促进联盟成员间的资源共享，资金、技术、市场、知识产权等方面的合作，协调联盟成员关系，推动产业链上下游的深入融合、产需对接和区域合作，加速我国深远海海洋工程技术与产品的普及应用；</li>
            <li>制定产业自律准则，研究评估产业发展环境，提出政策建议，维护行业和企业的利益及合法权益，维护产业安全； </li>
            <li>建立与金融机构的协作平台，疏通业界资金渠道，降低融资成本；</li>
            <li>总结经验，加强推广，搭建我国深远海海洋工程产业信息交流、教育培训、展览展示等平台，推动国内外合作交流；</li>
            <li>开展多种形式的国际合作交流，推动联盟会员与国外相关组织、企业的联系和交流，充分利用国内外两个市场、两种资源，塑造中国海洋工程的品牌形象，提升影响力； </li>
            <li>承担政府、成员单位委托的其它业务。  </li>
        </ol>
        
      </div>
    </div>
    <!-- 第三章 -->
    <div class="chapter_03">
      <p><strong>第三章 联盟组织机构</strong></p>
      <div class="chapter_content">
        <p>
          <strong>第七条</strong>&nbsp;
           联盟由联盟代表大会、理事会、战略指导委员会、秘书处和专项工作组等组成。联盟代表大会是联盟的最高权力机构，战略指导委员会是联盟的战略指导和资源支持机构，理事会是联盟决策机构，秘书处是联盟日常办事机构，专项工作组是根据联盟工作需要设立的专业工作机构。 
        </p>
        <p><strong>第八条</strong>&nbsp;联盟代表大会 </p>
        <!-- <div class="chapter_03_olBox"> -->
            <ol class="ol">
                <li>组成:联盟代表大会由联盟全体成员组成，是联盟的最高权力机构。</li>
                <li class="kuohao_box">职责:
                    <ol>
                        <li>制定和修改联盟章程； </li>
                        <li>审议理事会关于理事会会长的提案，并按规定表决决定；  </li>
                        <li>选举理事会会长、副理事会会长，亦为联盟主席、副主席；  </li>
                        <li>审议批准理事会的工作报告和财务报告；   </li>
                        <li>审议批准理事会提出的联盟发展规划和年度工作计划；   </li>
                        <li>决定联盟的变更、终止、清算事宜；   </li>
                        <li>决定其他重大事宜。    </li>
                    </ol>
                </li>
               <li class="kuohao_box">议事规则
                    <ol>
                        <li>联盟大会会议包括联盟大会年会和联盟大会临时会议，由联盟理事会会长或秘书长召集。 </li>
                        <li>秘书处应当对通过的决议做成会议记录/决议，出席会议的成员代表应当在会议记录/决议上签名，签名可以现场签署，也可以传真或邮件确认。  </li>
                        <li>联盟大会每年召开一次；在联盟大会闭会期间，遇有重大或紧急情况，经半数以上联盟成员或理事会提议，可召开联盟大会临时会议。  </li>
                        <li>联盟大会需要全体成员参加，不能参加的成员向秘书处履行书面委托投票手续，委托该届大会主席单位投票，会议表决有三分之二以上参会成员通过的决定有效。   </li>
                    </ol>
                </li>
            </ol>
        <!-- </div> -->
        <p>
          <strong>第九条</strong>&nbsp;战略指导委员会  
        </p>
        <ol class="ol">
            <li>组成:由海洋工程行业政府主管部门负责人、院士、海工总装骨干企业母公司负责人、核心用户单位（三大石油公司）负责人、行业和企业/集团的知名专家15人左右组成，不可根据联盟实际需要适当增减。委员会设主任委员1名，副主任委员3～5名，委员若干名。主任委员和副主任委员由理事会聘任，每届任期三年，可连聘、连任。 </li>
            <li>职责:为联盟的发展战略、方向、规划、重大决策、年度重点工作进行指导、把关，提供决策、咨询意见和建议，保证联盟的高起点、前瞻性、先进性和权威性，并为联盟的发展提供资源性支持。</li>
              <li class="kuohao_box">议事规则
                    <ol>
                        <li>原则上一年召开一次会议，由委员会主任委员或理事会会长召集，3名以上委员提议，半数以上委员同意可召开临时会议； </li>
                        <li>委员会可自主提出相关议题，并对理事会提交的重要议案提供指导意见；如需表决，半数以上委员表决即可形成决议； </li>
                        <li>可列席联盟理事会会议。</li>
                        <li>秘书处应当对会议内容做成会议记录/决议，出席会议的成员代表应当在会议记录/决议上签名，签名可以现场签署，也可以传真或邮件确认。 </li>
                    </ol>
              </li>
        </ol>
        <p><strong>第十条</strong>&nbsp;联盟理事会 </p>
            <ol class="ol">
              <li class="kuohao_box">组成
                    <ol>
                        <li>联盟理事会是联盟的领导机构，由理事成员单位组成，设理事会会长1名、副理事会会长若干名、理事若干名，数量不限，任期三年，由联盟成员推举产生。 </li>
                        <li>联盟发起人单位均为联盟副理事会会长单位，理事会会长单位采取发起人单位轮流坐庄的方式或由联盟成员推举产生。理事会会长单位在履职期间，应运用自身影响力和资源，努力促进联盟的发展。 </li>
                    </ol>
                </li>
              <li class="kuohao_box">职责
                    <ol>
                        <li>决定联盟临时代表大会的召开；</li>
                        <li>执行联盟代表大会的决议； </li>
                        <li>提请审议联盟章程修改方案； </li>
                        <li>推荐理事会会长候选单位，并提请联盟代表大会审议； </li>
                        <li>审议和批准联盟副理事会会长单位和理事单位的申请； </li>
                        <li>负责解释联盟章程；  </li>
                        <li>审批联盟的基本管理制度；  </li>
                        <li>提出联盟发展规划和年度工作计划；  </li>
                        <li>向联盟代表大会报告工作和财务状况；  </li>
                        <li>决定联盟秘书处秘书长、副秘书长的任免；决定各工作组组长的任免；  </li>
                        <li>审议批准联盟机构的设置和变动；  </li>
                        <li>领导联盟各机构开展工作；  </li>
                        <li>决定其他重大事项。  </li>
                    </ol>
                </li>
              <li class="kuohao_box">议事规则
                    <ol>
                        <li>理事会会议包括年会和临时会议，由理事会会长或秘书长提议召开；秘书处应当对通过的决议做成会议记录/决议，出席会议的成员代表应当在会议记录/决议上签名。</li>
                        <li>理事会年会每年召开一至两次；在闭会期间，遇有重大或紧急情况；经三名以上理事提议，半数以上理事会成员同意，可以召开理事会临时会议； </li>
                        <li>理事会会议需要全体成员参加，不能参加的成员向秘书处履行书面委托投票手续，委托该届理事会会长单位投票，会议表决有三分之二以上参会成员通过的决定有效。 </li>
                    </ol>
                </li>
            </ol>
        <p><strong>第十一条</strong>&nbsp;联盟秘书处  </p>
            <ol class="ol">
              <li class="kuohao_box">组成
                    <ol>
                        <li>联盟下设秘书处，设秘书长1名，副秘书长若干名，由理事会任命。秘书长、副秘书长任期三年，换届时由理事会决定是否连任。 </li>
                        <li>秘书处负责联盟的日常行政事务工作。 </li>
                        <li>秘书处下设若干专项工作组，负责各专项工作。 </li>
                        <li>秘书处需设立独立的办公地点，秘书处所在地即为联盟所在地。  </li>
                    </ol>
                </li>
              <li class="kuohao_box">职责
                    <ol>
                        <li>执行联盟大会及理事会决议，负责联盟日常工作；</li>
                        <li>执行联盟大会及理事会决议，负责联盟日常工作； </li>
                        <li>负责筹备联盟代表大会会议及理事会会议；  </li>
                        <li>起草联盟年度工作报告；  </li>
                        <li>起草年度财务预算及决算报告；  </li>
                        <li>起草联盟发展规划和年度工作计划；   </li>
                        <li>负责受理、审核、批复职责范围内的联盟成员单位提交的各类申请，超出职责范围的报请联盟理事会、联盟大会审议批准；  </li>
                        <li>负责受理联盟成员升级为理事、副理事会会长、理事会会长的申请，并按相关程序提交联盟理事会、联盟大会审议；   </li>
                        <li>依据联盟有关制度，提议除名违规成员及受理成员退出联盟的申请；   </li>
                        <li>决定联盟秘书处秘书长、副秘书长的任免；决定各工作组组长的任免；  </li>
                        <li>负责联盟代表大会和理事会委托的其他事宜。   </li>
                    </ol>
                </li>
              <li class="kuohao_box">管理机制 
                    <ol>
                        <li>实行理事会领导下的秘书长负责制。依据本章程和内部管理制度开展联盟创新活动及项目管理、项目经费监督等日常工作。根据联盟实际情况需要，经理事会同意或授权，不断完善联盟管理机制和制度； /决议，出席会议的成员代表应当在会议记录/决议上签名。</li>
                        <li>秘书长具有联盟秘书处除副秘书长及下设专项工作组除组长以外的人事任免权，人事变动情况需通报联盟理事会。 </li>
                    </ol>
                </li>
            </ol>

        <p><strong>第十二条</strong>&nbsp;专项工作组</p>
        <ol class="ol">
            <li>为提高联盟的整体效率，对于联盟的具体工作进行专业化分工，根据工作需要，成立专项工作组。专项工作组人员由联盟成员单位委派以及有关专家、学者和行业 深人士构成，针对联盟市场、技术、标准、质量、创新、产融结合、国际合作、政策研究等领域开展专项工作； </li>
            <li>各工作组在联盟理事会及秘书处的统一协调下开展工作。各工作组设组长一名，副组长若干名（如需要）。组长及副组长人选由秘书长推荐，由理事会任命，组长副组长任期三年，可以连任；</li>
            <li>联盟可以根据需要成立新的专项工作组或解散现有的工作组。</li>
            <li>对涉及联盟重大技术发展方向、重大技术项目、重大技术成果等，提出咨询意见和建议，供联盟理事会决策； </li>
        </ol>
      </div>
    </div>

    <!-- 第四章 -->
    <div class="chapter_04">
        <p><strong>第四章 联盟成员</strong></p>
      <div class="chapter_content">
        <p>
          <strong>第十三条</strong>&nbsp;联盟成员均为单位成员，基本条件如下： 
        </p>
        <ol class="ol">
            <li>在我国有关部门注册，具有合法独立法人资格； </li>
            <li>自愿申请，承认并遵守本章程 </li>
            <li>从事下列任何一项或多项深远海海洋工程领域的： </li>
            <li class="kuohao_box">议事规则
                    <ol>
                        <li>总装制造集团或企业；</li>
                        <li>用户单位；</li>
                        <li>权威认证机构；</li>
                        <li>业内知名研发机构、大专院校、科研院所；</li>
                        <li>关键配套设备生产企业；</li>
                        <li>技术服务企业及机构；</li>
                        <li>其他知名权威组织或机构； </li>
                    </ol>
                </li>
            <li>联盟成员之间互不负责债权债务连带责任； </li>    
            <li>享受联盟成员权利，承担联盟成员义务。  </li>    
        </ol>

        <p>
          <strong>第十四条</strong>&nbsp;联盟成员分为主席/理事会会长成员、副主席/副理事会会长成员、理事成员和非理事成员（普通成员）。 
        </p>

        <ol class="ol">
            <li class="kuohao_box">主席/理事会会长成员：
                    <ol>
                        <li>首届主席成员原则上由联盟总发起人（指联盟倡议发起单位，积极参加联盟成立初期的活动，在联盟成立初期作为总组织单位或总召集人，并自愿作为总发起人的企业、单位、院校或机构）担任；</li>
                        <li>主席成员一般不超过1个；</li>
                        <li>主席成员每三年换届，由发起人成员单位轮流坐庄产生。</li>
                    </ol>
            </li>
            <li class="kuohao_box">副理事会会长成员：
                    <ol>
                        <li>原则上由联盟主要发起人（指积极参加联盟成立初期的活动，在联盟成立初期作为主要参与单位，并自愿作为主要发起人的企业、单位、院校或机构）担任；</li>
                        <li>副主席成员名额若干，根据联盟工作需要确定;</li>
                    </ol>
            </li>
            <li class="kuohao_box">理事会成员：
                    <ol>
                        <li>联盟发起人（指参加联盟成立初期的活动，并自愿作为发起人的企业、单位、院校或机构）为理事会成员；</li>
                        <li>承认本章程的非理事会成员，对深远海海洋工程产业有重要贡献和影响力，按照联盟章程规定提出申请，经理事会三分之二以上表决通过，履行理事义务，即可成为联盟理事会成员。</li>
                    </ol>
            </li>
            <li class="kuohao_box">非理事会成员：
                    <p>承认本章程，按照章程规定向联盟秘书处提出申请，经秘书处按照本章程相关规定完成相应手续的，即可成为非理事会成员。</p>
            </li>
        </ol>

        <p>
          <strong>第十五条</strong>&nbsp;联盟成员的权利
        </p>
        <ol class="ol">
            <li class="kuohao_box">非理事成员的权利 
                    <ol>
                        <li>参加联盟代表大会会议，参与讨论和表决联盟发展相关的重大决策、决议和其他事项，具有表决权、选举权和被选举权； </li>
                        <li>有权向联盟提交议案，对联盟工作提出建议，并进行监督； </li>
                        <li>有权申请成为理事会成员； </li>
                        <li>有权参加联盟组织的交流、培训、展览、国际合作等活动；  </li>
                        <li>享有使用联盟公共信息的权利；  </li>
                        <li>享有优先与联盟成员相互协作的权利；   </li>
                        <li>有权获得联盟提供的其他服务；  </li>
                        <li>享有参与联盟项目的优先权和优惠权；  </li>
                        <li>享有自由退出联盟的权利。  </li>
                    </ol>
            </li>
            <li class="kuohao_box">理事会成员的权利 
                    <ol>
                        <li>非理事成员的权利； </li>
                        <li>参加理事会会议，并享有表决权、选举权和被选举权； </li>
                        <li>有权申请成为副理事会会长、理事会会长单位； </li>
                        <li>有权向理事会会长、副主席或秘书长提议召开临时联盟代表大会和临时理事会会议； </li>
                        <li>有权对联盟财务收支进行查询； </li>
                        <li>享有自由退出理事会的权利。 </li>
                    </ol>
            </li>
        </ol>

        <p>
          <strong>第十六条</strong>&nbsp;联盟成员的义务 
        </p>
        <ol class="ol">
            <li class="kuohao_box">非理事成员的义务  
                    <ol>
                        <li>遵守联盟章程、制度和相关协议，执行联盟决议； </li>
                        <li>宣传联盟宗旨，维护联盟形象；  </li>
                        <li>保守联盟的商业秘密；  </li>
                        <li>及时向联盟反映企业相关信息，并提供有关资料；  </li>
                        <li>积极参与技术开发、推广、合作应用及产业化；  </li>
                        <li>积极承担和配合联盟的各项工作；   </li>
                        <li>维护联盟的利益和合法权益；   </li>
                        <li>未经理事会书面授权，不得以联盟名义开展任何活动；   </li>
                        <li>任何联盟成员违反本章程和国家法律法规，给联盟或其成员带来损失的，须承担赔偿责任；  </li>
                        <li>按联盟规定缴纳会费。 </li>
                    </ol>
            </li>
            <li class="kuohao_box">理事会成员的义务 
                    <ol>
                        <li>普通联盟成员的义务；  </li>
                        <li>关心支持联盟工作，按时参加理事会活动，积极完成理事会分派的任务。  </li>
                    </ol>
            </li>
        </ol>
        <p>
          <strong>第十七条</strong>&nbsp;联盟的加入  
        </p>
        <ol class="ol">
            <li class="kuohao_box">成员单位申请加入流程：   
                    <ol>
                        <li>向联盟秘书处提出申请，并提交相关书面材料，内容包括：  
                            <ul>
                                <li>①填写《中国深远海海工装备技术产业联盟成员单位申请表》；</li>
                                <li>②提交法人证件(企业法人营业执照；《税务登记证》正、副本；组织机构代码证)复印件； </li>
                                <li>③提交申请单位介绍材料； </li>
                                <li>④提交申请单位在深远海海洋工程领域开展的工作情况。 </li>
                            </ul>
                        </li>
                        <li>联盟秘书处按照联盟章程规定进行资格审核；    </li>
                        <li>通过资格审核的成员单位申请将在联盟官方网站上予以公示，时间为10个工作日；     </li>
                        <li>联盟秘书处向公示期满无异议、并已缴纳相关会费的申请单位颁发中国深远海海洋工程装备技术产业联盟成员证书，证书签发时间即为申请单位正式入盟时间；     </li>
                        <li>联盟秘书处应定期将新加入联盟的成员单位情况报备理事会，并在下次联盟代表大会通报。     </li>
                    </ol>
            </li>
            <li class="kuohao_box">理事单位申请流程：  
                    <ol>
                        <li>申请单位须为联盟成员单位；   </li>
                        <li>向联盟秘书处提出申请，并提交《中国深远海海洋工程装备技术产业联盟理事单位申请表》；  </li>
                        <li>提交申请单位在深远海海工领域所开展的工作及取得的主要成绩；   </li>
                        <li>提交申请单位所参与的联盟活动清单；   </li>
                        <li>秘书处将申请报送理事会；   </li>
                        <li>理事会讨论并表决，通过后形成决议，并向联盟代表大会通报；    </li>
                        <li>审议通过即成为联盟理事单位，享受理事单位权利和义务，联盟秘书处向获得理事会同意、并已缴纳相关资费的申请单位颁发中国深远海海洋工程装备技术产业联盟理事单位证书。   </li>
                    </ol>
            </li>
            <li class="kuohao_box">副理事会会长单位申请流程：  
                    <ol>
                        <li>申请单位须为联盟理事单位；    </li>
                        <li>向联盟秘书处提出申请，并提交《中国深远海海洋工程装备技术产业联盟副理事会会长单位申请表》；   </li>
                        <li>提交申请单位在深远海海工领域的行业地位、所开展的工作及取得的主要成绩；    </li>
                        <li>提交申请单位所参与联盟活动清单；    </li>
                        <li>秘书处将申请报送理事会；    </li>
                        <li>理事会讨论、表决，通过后形成决议，并向联盟代表大会通报；     </li>
                        <li>表决通过即成为中国深远海海工产业联盟副理事会会长单位，联盟秘书处向获得联盟代表大会同意、并向已缴纳相关资费的申请单位颁发中国深远海海洋工程备技术产业联盟副理事会会长单位证书。   </li>
                    </ol>
            </li>
            <li class="kuohao_box">联盟理事会会长单位产生流程  
                    <ol>
                        <li>根据第十四条，联盟理事会会长换届时，由秘书处征询主要发起人单位意见，确定新一届理事会会长单位，并报理事会审议后，报联盟大会表决决定；    </li>
                        <li>如联盟大会表决没有通过，由理事会选择新的会长单位报联盟大会表决决定；   </li>
                        <li>联盟秘书处向获得联盟代表大会同意、并向已缴纳相关会费的申请单位颁发中国深远海海洋工程装备技术产业联盟理事会会长单位证书。    </li>
                    </ol>
            </li>
        </ol>

        <p>
          <strong>第十八条</strong>&nbsp;联盟成员退出与除名   
        </p>
        <ol>
             <li>向联盟理事会提交书面退出声明，并交回联盟成员证书； </li>
             <li>自提交退出声明日三十日后退出联盟。成员退出后，不得再以联盟成员单位的名义对外开展各类活动，已缴纳的会费不予退还； </li>
             <li>连续两年不缴纳会费或不参加联盟活动的，视为自动退出。 </li>
             <li>凡依法破产、关闭、解散、注销的联盟成员即自动失去成员资格。 </li>
             <li>联盟成员违反本章程及国家有关法律法规，或连续两年不履行成员义务、不参加联盟活动，经秘书处核实，可提请理事会决定对其予以除名。 </li>
             <li>联盟成员自愿退出联盟或被除名前，应妥善解决遗留问题；如对联盟、成员单位或相关事项造成损失的，退出者应依照国家有关法律法规承担相应责任。 </li>
             <li>成员退出或除名后两年之内不得再次申请加入联盟。 </li>
             <li>秘书处应在联盟官网上及时公布联盟成员退出情况，并定期向联盟大会通报。 </li>
        </ol>

      </div>
    </div>
    <!-- 第五章 -->
    <div class="chapter_05">
         <p><strong>第五章 财务和资产管理</strong></p>
         <div class="chapter_content">
            <p>
            <strong>第十九条</strong>&nbsp;联盟经费来源及用途  
            </p>
            <ol class="ol">
                <li>联盟经费来源:国家资助的专项经费或社会捐赠，联盟举办的各类活动和服务的收入，成员单位按联盟规定缴纳的会费，其他合法收入。</li>
                <li class="kuohao_box">联盟会费的收取标准：  
                    <ol>
                        <li>联盟主席/理事会会长单位：50000元人民币/年；    </li>
                        <li>联盟副主席/副理事会会长单位：30000元人民币/年；   </li>
                        <li>联盟理事单位：10000元人民币/年；    </li>
                        <li>联盟非理事成员单位：5000元人民币/年。    </li>
                        <li>联盟会费可以按一届一次性付清，也可以按年度缴付，缴费应在当年3月31日前完成。如在当年6月30日之后提请加入联盟并经审核通过的，当年会费减半。    </li>
                    </ol>
                </li>
                <li>联盟经费用途: 主要用于联盟开展日常工作、组织会议、开展各类活动、以及相关专项工作等，其用途必须为本章程规定的业务范围。 </li>

            </ol>
            <p>
            <strong>第二十条</strong>&nbsp;联盟依据国家有关法律法规，按照国家相关资产、财务规定，建立严格的财务管理制度，保证会计资料合法、完整、真实。   
            </p>
            <p>
            <strong>第二十一条</strong>&nbsp;理事会委托秘书处对联盟经费设立独立账目进行管理，实现专账专用，独立核算，接受理事会的监督和认可的第三方的审计。   
            </p>
            <p>
            <strong>第二十二条</strong>&nbsp;国家拨款、社会捐赠、资助资金，必须接受审计机关的监督、审查，并将资金使用情况以适当方式向社会公布。   
            </p>
         </div>
    </div>


    <!-- 第六章 -->
    <div class="chapter_06">
         <p><strong>第六章  附 则</strong></p>
         <div class="chapter_content">
            <p>
            <strong>第二十三条</strong>&nbsp;深远海海工产业联盟因终止、解散或分立、合并等原因需要解体时，由理事会提出提案，经联盟代表大会2/3以上成员表决、同意后，方可生效。   
            </p>
            <p>
            <strong>第二十四条</strong>&nbsp;联盟终止前，须在理事会的领导下成立清算小组，专职清理债权债务。剩余财产须在具有审计资质的财务审计机构监督下，按照国家有关规定，用于发展与联盟  宗旨相关的事业。    
            </p>
            <p>
            <strong>第二十五条</strong>&nbsp;本章程自联盟成立及首届联盟大会通过后生效。    
            </p>
            <p>
            <strong>第二十六条</strong>&nbsp;本章程的解释权属联盟理事会。   
            </p>
         </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.con2_page {
//   font-size: 14px;
  font-size: 16px;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
    // line-height: 30px;
    line-height: 35px;

  .titleBox {
    .font_24 {
      font-size: 24px;
    }
  }
   .chapter_01,.chapter_02,.chapter_03,.chapter_04,.chapter_05,.chapter_06{
       width: 100%;
  }
  .chapter_content{
      text-align: left !important;
      .text_indent{
          text-indent: 2em;
      }
  }
 
  .chapter_02{
      .ol{
          li{
            //   margin: 20px 0;
              line-height: 30px;
              
          }
      }
  }
  .chapter_03,.chapter_04,.chapter_05{
      .chapter_content{
          .ol{
              .kuohao_box ol{
                  padding-left: 0 !important;
                counter-reset: list;
              }
              .kuohao_box ol>li{
                 list-style: none;
              }
              .kuohao_box ol>li:before{
                 content: "("counter(list) ") ";
                 counter-increment: list;
              }
              li{
                //   margin: 20px 0;
              }
          }
      }
  }
 ul li{
      list-style: none;
  }
}
</style>