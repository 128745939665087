<template>
	<div class="register_box">
		<div class="tap_box">
			<div class="tap_item">找回密码</div>
		</div>
		<div class="input_box" v-if="step === 0">
			<el-input class="input_cont" placeholder="绑定的邮箱号" v-model="email" clearable></el-input>
			<el-input class="input_cont" placeholder="验证码" v-model="verifiyCode" @keyup.enter.native="submit">
				<template slot="append">
					<div class="get_code" @click="getCode">
						<span v-if="count != 60">重新获取 {{ count }} S</span>
						<span v-else class="active_get_code">获取验证码</span>
					</div>
				</template>
			</el-input>
		</div>
		<div class="input_box" v-else>
			<el-input class="input_cont" placeholder="新密码" v-model="password" show-password clearable></el-input>
			<el-input class="input_cont" placeholder="确认新密码" v-model="passwordAgin" show-password clearable @keyup.enter.native="submit"></el-input>
		</div>
		<div class="login_btn" @click="submit()">{{step?'确认':'下一步'}}</div>
		<div class="bottom_to_login">
			<span class="login_text" @click="navigatorTo('/login')">已知密码？前往登录</span>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	data() {
		return {
			email:'',
			password:'',
			passwordAgin:'',
			verifiyCode:'',
			count: 60,
			timeId:null,
			step:0,	//步骤
			userToken:'',
		};
	},
	watch:{
		forgetPwdSMSCodeResult(){
			if(this.forgetPwdSMSCodeResult.success){
				this.$message({
					showClose: true,
					message:'获取验证码成功',
					type: 'success'
				});
				this.count = 59;
				this.timeId = setInterval(()=>{
					this.count -= 1;
					if(this.count == 0){
						this.count = 60;
						clearInterval(this.timeId);
					}
				},1000)
			}else{
				this.$message({
					showClose: true,
					message:this.forgetPwdSMSCodeResult.message,
					type: 'error'
				});
			}
		},
		forgetLoginResult(){
			if(this.forgetLoginResult.success){
				// console.log(this.forgetLoginResult)
				this.userToken = this.forgetLoginResult.data.token;
				this.step = 1;
			}else{
				this.$message({
					showClose: true,
					message:this.forgetLoginResult.message,
					type: 'error'
				});
			}
		},
		resetPasswordResult(){
			if(this.resetPasswordResult.success){
				this.$message({
					showClose: true,
					message:'密码修改成功,请登录！',
					type: 'success'
				});
				this.navigatorTo('/login');
			}else{
				this.$message({
					showClose: true,
					message:this.resetPasswordResult.message,
					type: 'error'
				});
			}
		},
	},
	computed: {
		...mapState({
			forgetPwdSMSCodeResult: state => state.AboutLogin.forgetPwdSMSCodeResult,
			forgetLoginResult: state => state.AboutLogin.forgetLoginResult,
			resetPasswordResult: state => state.AboutLogin.resetPasswordResult,
		}),
	},
	methods: {
		...mapActions({
			forgetPwdSMSCode:'AboutLogin/forgetPwdSMSCode',
			resetPassword:'AboutLogin/resetPassword',
			forgetPhoneLogin:'AboutLogin/forgetPhoneLogin',
		}),
		navigatorTo(path){
			this.$router.replace(path);
		},
		getCode(){
			let reg = /^[0-9A-Za-z][\.-_0-9A-Za-z]*@[0-9A-Za-z]+(\.[0-9A-Za-z]+)+$/;
			// let reg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
			if (!reg.test(this.email)) {
				this.$message({
					showClose: true,
					message: '邮箱号不合规范',
					type: 'error'
				});
			  return
			}
			if(this.count != 60){
				return
			}
			let params = {
				email:this.email,
			}
			this.forgetPwdSMSCode(params);
		},
		submit(){
			if(this.step === 0){
				let params = {
					email:this.email,
					verifiyCode:this.verifiyCode
				}
				this.forgetPhoneLogin(params);
			}else if(this.step === 1){
				if(!this.password){
					this.$message({
						showClose: true,
						message: '密码不能为空',
						type: 'error'
					});
					return
				}
				if(this.password != this.passwordAgin){
					this.$message({
						showClose: true,
						message: '两次密码输入不一致',
						type: 'error'
					});
					return
				}
				let params = {
					userToken:this.userToken,
					passwordInfo:this.$aesEncrypt(JSON.stringify({
						password:this.password,
					}))
				}
				this.resetPassword(params);
			}
		},
	},
	mounted() {
		
	}
};
</script>

<style lang="scss" scoped>
.register_box {
	width: 100%;
	height: auto;
	.tap_box {
		width: 100%;
		height: 60px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content:center;
		.tap_item {
			width: auto;
			height: 38px;
			font-size: 20px;
			font-weight: 600;
			box-sizing: border-box;
			border-bottom: 3px solid $theam-bgcolor;
			color: $theam-bgcolor;
		}
	}
	.input_box {
		width: 100%;
		height: auto;
		.input_cont {
			margin-top: 20px;
			.get_code{
				width: 110px;
				height: 38px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.active_get_code{
				color: $theam-color;
			}
		}
		.code_box {
			width: 100%;
			height: 40px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}
	.login_btn{
		width: 100%;
		height: 44px;
		border-radius: 44px;
		color: #fff;
		background-color: $theam-bgcolor;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		margin-top: 60px;
	}
	.bottom_to_login{
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: #999;
		font-size: 14px;
		.login_text{
			cursor: pointer;
		}
	}
}
</style>
