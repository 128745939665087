<template>
	<div class="systemCode_box">
		<div class="left_container">
			<div class="input_item">
				<div class="key">应用协议</div>
				<el-input placeholder="如:https//" v-model="httpText" clearable></el-input>
			</div>
			<div class="input_item">
				<div class="key">企业域名</div>
				<el-input placeholder="如:containercode.com" v-model="domainName" clearable></el-input>
			</div>
			<div class="input_item">
				<div class="key">企业二级域名</div>
				<el-input placeholder="如:88.355" v-model="domain2Name" clearable></el-input>
			</div>
			<div class="input_item">
				<div class="key">物料类型</div>
				<el-input placeholder="如:A1" v-model="materialType" clearable></el-input>
			</div>
			<div class="input_item">
				<div class="key">物料编码</div>
				<el-input placeholder="如:B1" v-model="materialCode" clearable></el-input>
			</div>
			<div class="input_item textarea_box">
				<div class="key">其他字段</div>
				<el-input
				  type="textarea"
				  :rows="3"
				  placeholder="请输入其他字段,以'/'符号分隔,例如:C1/D1/E1,其中C1、D1、E1代表其他字段"
				  v-model="otherText">
				</el-input>
			</div>
			<div class="input_item textarea_box">
				<div class="key">生成结果</div>
				<el-input
					:disabled="true"
				  type="textarea"
				  :rows="3"
				  placeholder="如:https//containercode.com/88.126.1/A1/B1/C1/D1/E1"
				  v-model="resultVal">
				</el-input>
			</div>
			<div class="btn_box">
				 <el-button type="primary" @click='createQrcode'>立即生成二维码</el-button>
			</div>
		</div>
		<div class="right_box">
			<div class="right_code_box" v-if="showCodeBox">
				<div id="qrcode" ref="qrcode" v-if="showCode"></div>
				<div class="text" v-else>此处预览二维码</div>
			</div>
			<div class="code_btn_box" v-if="showCode">
				<el-button type="info" plain @click="downCodeImg">下载二维码</el-button>
				<el-button type="success" plain @click="handlePrint">打印二维码</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'; // 引入qrcode
	import { getLodop } from '../../../common/lib/LodopFuncs.js';
	import {mapActions, mapState} from 'vuex'
	export default {
		data(){
			return {
				httpText:'',	//应用协议
				domainName:'',	//企业域名
				domain2Name:'',	//企业二级域名
				materialType:'',	//物料类型
				materialCode:'',	//物料编码
				otherText:'',	//其他字段
				showCode:false,
				showCodeBox:true,
			}
		},
		computed:{
			resultVal(){
				let domain2Name = this.domain2Name;
				if(domain2Name){
					domain2Name = `/${domain2Name}`
				}
				let materialType = this.materialType;
				if(materialType){
					materialType = `/${materialType}`
				}
				let materialCode = this.materialCode;
				if(materialCode){
					materialCode = `/${materialCode}`
				}
				let otherText = this.otherText;
				if(otherText){
					otherText = `/${otherText}`
				}
				
				let str = `${this.httpText}${this.domainName}${domain2Name}${materialType}${materialCode}${otherText}`;
				return str
			},
		},
		methods:{
			createQrcode(){	//生成二维码
				if(!this.resultVal){
					this.$message({
						showClose: true,
						message:'生成结果不能为空',
						type: 'error'
					});
					return
				}
				this.showCode = true;
				this.showCodeBox = false;
				this.$nextTick(()=>{
					this.showCodeBox = true;
					this.$nextTick(()=>{
						new QRCode('qrcode', {
						  width: 300,
						  height: 300,
						  text: this.resultVal, // 二维码地址
						});
					})
				})
			},
			downCodeImg(){
				let myCanvas = document.getElementById("qrcode").getElementsByTagName("canvas");
				let a = document.createElement("a");
	      a.href = myCanvas[0].toDataURL("image/png");
	      a.download = "海工标识二维码";
	      a.click();
			},
			handlePrint(){	//打印单个二维码
				let LODOP = getLodop();
				LODOP.PRINT_INIT("");
				LODOP.SET_PRINT_PAGESIZE(1, 440, 440,"");
				LODOP.ADD_PRINT_BARCODE(5, 5, 180, 180, "QRCode", `${this.resultVal}`);
				LODOP.PREVIEW(); //预览(预览打印无脚标)
				// LODOP.PRINT();  //打印
			},
		},
		mounted() {
			
		}
	}
</script>
<style type="text/css">
	.systemCode_box .el-textarea.is-disabled .el-textarea__inner{
		color: #000 !important;
	}
</style>
<style lang="scss" scoped>
	.systemCode_box{
		margin: 20px 0 40px 0;
		width: 100%;
		height: auto;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		.left_container{
			width: 50%;
			padding: 20px;
			border-radius: 10px;
			background-color: #fff;
			border: 1px solid #eee;
			.input_item{
				width: 100%;
				min-height: 60px;
				display: flex;
				flex-direction: row;
				align-items: center;
				.key{
					width: 130px;
				}
			}
			.textarea_box{
				margin: 10px 0;
			}
			.btn_box{
				width: 100%;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.right_box{
			width: 360px;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.right_code_box{
				width: 360px;
				height: 360px;
				background-color: #fff;
				border-radius: 10px;
				box-sizing: border-box;
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #eee;
				.text{
					font-size: 30px;
					color: #999;
				}
			}
			.code_btn_box{
				width: 100%;
				height: 60px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
