<!-- 联盟成员 -->
<template>
<div class=''>
    {{list}}
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "axios";
export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
    pageQuery: {
        current: 1, //页码
        size: 10, //每页显示条数
        type: "",//类型
        attr: "",//属性
        name: "",//企业名称
      },
      list:[],
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
     select() {
      axios({
        url: "http://www.codia.org.cn/api/portal/league/list",
        headers: {
          "Content-Type": "application/json",
        },
        method: "get",
        data: this.pageQuery,
      }).then((res) => {
        const { data } = res;
        // console.log(data);
      });
    },
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    this.select()
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类

</style>