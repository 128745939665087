<template>
	<div class="createdQRcode_box">
		<div class="code_cont_box">
			<el-tabs v-model="activeName" @tab-click="handleClick">
			  <el-tab-pane label="海工二维码打印服务" name="first">
			    <SystemCode></SystemCode>
			  </el-tab-pane>
			  <el-tab-pane label="随机生成二维码" name="second">
					<RandomCode></RandomCode>
			  </el-tab-pane>
			  <el-tab-pane label="二维码规范" name="third">
					<CodeSpecification></CodeSpecification>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex'
	import SystemCode from './systemCode.vue'
	import RandomCode from './randomCode.vue'
	import CodeSpecification from './codeSpecification.vue'
	export default {
		components:{
			SystemCode,
			RandomCode,
			CodeSpecification,
		},
		data(){
			return {
				activeName:'first',
				
			}
		},
		computed:{
			
		},
		methods:{
			handleClick(tab, event) {
			//   console.log(tab, event);
			},
		},
		mounted() {
			
		}
	}
</script>

<style lang="scss" scoped>
	.createdQRcode_box{
		width: 100%;
		height: 100%;
		min-height: calc(100vh - 320px);
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		padding-top: 10px;
		background-color: #f6fafd;
		.code_cont_box{
			width: $heightBaseContent;
			height: auto;
		}
	}
</style>
