<template>
	<div class="unloginQRcode_box">
		<div id="eqCode" ref="eqCode"></div>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex'
	import QRCode from 'qrcodejs2'; // 引入qrcode
	export default {
		data(){
			return {
				searchVal:'',
			}
		},
		computed:{
			...mapState({
				baseUrl: state => state.baseUrl,
			}),
		},
		methods:{
			init(searchVal){
				this.searchVal = searchVal;
				new QRCode('eqCode', {
				  width: 260,
				  height: 260,
				  text: `${this.baseUrl}/${this.searchVal}`, // 二维码地址
				});
			},
		},
		mounted() {
			
		}
	}
</script>

<style lang="scss" scoped>
	.unloginQRcode_box{
		width: 100%;
		height:400px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
