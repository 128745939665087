<template>
	<div class="codeSpecification_box">
		<div class="specification_header">
			工业互联网-海工装备二维码编码规则
		</div>
		<div class="table_box">
			<el-table
				:data="tableData"
				class="table_cont_box"
				:row-class-name="tableRowClassName"
				:border="true">
				<el-table-column
					type="index"
					label="序号"
					width="50">
				</el-table-column>
				<el-table-column
					prop="title"
					label="数据内容"
					width="150">
				</el-table-column>
				<el-table-column
					prop="type"
					label="代码类型	"
					width="100">
				</el-table-column>
				<el-table-column
					prop="length"
					label="长度"
					width="100">
				</el-table-column>
				<el-table-column
					prop="unit"
					label="单位"
					width="100">
				</el-table-column>
				<el-table-column
					prop="attribute"
					label="属性"
					width="100">
				</el-table-column>
				<!-- <el-table-column
					prop="example"
					label="示例"
					width="180">
				</el-table-column> -->
				<el-table-column
					prop="remarks"
					label="备注"
					>
				</el-table-column>
				
			</el-table>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex'
	export default {
		data(){
			return {
				tableData: [
					{
						title: 'http或https',
						type: '字母',
						length: '4或5',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '固定为http或https字符',
					},{
						title: '//',
						type: '符号',
						length: '2',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '固定两个斜杠',
					},{
						title: '域名',
						type: '数字字母',
						length: '10',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '如果域名过长，可使用短的二级域名跳转过来',
					},{
						title: '/',
						type: '分隔符',
						length: '1',
						unit: '',
						attribute: '必选',
						// example: '/',
						remarks: '',
					},{
						title: '88.',
						type: '数字',
						length: '2',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '国家顶级节点',
					},{
						title: 'XXX.',
						type: '数字',
						length: '3',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '海工装备行业二级节点',
					},
					{
						title: 'XXX',
						type: '数字',
						length: '变长',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '企业ID',
					},{
						title: '/',
						type: '符号',
						length: '1',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '',
					},
					{
						title: '物料类型代码',
						type: '数字字母',
						length: '变长 不超过4位',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '物料类型代码为数字字母，长度1~4位，主要是物料类型代码',
					},{
						title: '.',
						type: '分割符符号',
						length: '1',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '',
					},{
						title: '物料产品层次代码',
						type: '数字字母',
						length: '变长 不超过6位',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '物料产品层次代码为数字字母，长度1~6位，不超过6位。里面可以填充物料分类的大中小类',
					},{
						title: '.',
						type: '分割符符号',
						length: '1',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '',
					},{
						title: '物料编码codeID',
						type: '数字字母',
						length: '变长',
						unit: '',
						attribute: '必选',
						// example: '',
						remarks: '物料编码规则详见SAP、MAS编码规则',
					},
					// {
					// 	title: '尺寸代码',
					// 	type: '数字字母',
					// 	length: '2',
					// 	unit: '无',
					// 	attribute: '必选',
					// 	example: '22',
					// 	remarks: 'ISO 6346中定义的集装箱尺寸和箱型代码。',
					// },{
					// 	title: '箱型代码',
					// 	type: '数字字母',
					// 	length: '2',
					// 	unit: '无',
					// 	attribute: '必选',
					// 	example: 'G1',
					// 	remarks: 'ISO 6346中定义的集装箱尺寸和箱型代码。',
					// },{
					// 	title: '分隔符',
					// 	type: ';',
					// 	length: '1',
					// 	unit: '',
					// 	attribute: '必选',
					// 	example: ';',
					// 	remarks: '',
					// },{
					// 	title: '制造工厂代码',
					// 	type: '字母',
					// 	length: '4',
					// 	unit: '无',
					// 	attribute: '必选',
					// 	example: 'CIMC',
					// 	remarks: 'GB/T 33574中定义的集装箱生产序列号。',
					// },{
					// 	title: '尺寸箱型代码',
					// 	type: '数字字母',
					// 	length: '4',
					// 	unit: '无',
					// 	attribute: '必选',
					// 	example: '22G1',
					// 	remarks: 'GB/T 33574中定义的集装箱生产序列号。',
					// },{
					// 	title: '生产年份',
					// 	type: '数字',
					// 	length: '2',
					// 	unit: '无',
					// 	attribute: '必选',
					// 	example: '17',
					// 	remarks: 'GB/T 33574中定义的集装箱生产序列号。',
					// },{
					// 	title: '序列号',
					// 	type: '数字',
					// 	length: '6',
					// 	unit: '无',
					// 	attribute: '必选',
					// 	example: '000001',
					// 	remarks: 'GB/T 33574中定义的集装箱生产序列号。',
					// },{
					// 	title: '分隔符',
					// 	type: ';',
					// 	length: '1',
					// 	unit: '',
					// 	attribute: '必选',
					// 	example: ';',
					// 	remarks: '',
					// },{
					// 	title: '制造厂国别代码',
					// 	type: '字母',
					// 	length: '2',
					// 	unit: '无',
					// 	attribute: '必选',
					// 	example: 'CN',
					// 	remarks: 'CSC安全合格牌照上标记的批准国识别符号。',
					// },{
					// 	title: '分隔符',
					// 	type: ';',
					// 	length: '1',
					// 	unit: '',
					// 	attribute: '必选',
					// 	example: ';',
					// 	remarks: '',
					// },{
					// 	title: '首次检验日期',
					// 	type: '数字',
					// 	length: '8',
					// 	unit: '无',
					// 	attribute: '必选',
					// 	example: '20171001',
					// 	remarks: '',
					// },{
					// 	title: '分隔符',
					// 	type: ';',
					// 	length: '1',
					// 	unit: '',
					// 	attribute: '必选',
					// 	example: ';',
					// 	remarks: '',
					// },{
					// 	title: '总质量/总重',
					// 	type: '数字',
					// 	length: '变长',
					// 	unit: 'kg',
					// 	attribute: '必选',
					// 	example: '00000',
					// 	remarks: '',
					// },{
					// 	title: '分隔符',
					// 	type: ';',
					// 	length: '1',
					// 	unit: '',
					// 	attribute: '必选',
					// 	example: ';',
					// 	remarks: '',
					// },{
					// 	title: '空箱重量/自重',
					// 	type: '数字',
					// 	length: '变长',
					// 	unit: 'kg',
					// 	attribute: '必选',
					// 	example: '00000',
					// 	remarks: '',
					// },{
					// 	title: '分隔符',
					// 	type: ';',
					// 	length: '1',
					// 	unit: '',
					// 	attribute: '必选',
					// 	example: ';',
					// 	remarks: '',
					// },{
					// 	title: '有效载荷/净重',
					// 	type: '数字',
					// 	length: '变长',
					// 	unit: 'kg',
					// 	attribute: '必选',
					// 	example: '00000',
					// 	remarks: '',
					// },{
					// 	title: '分隔符',
					// 	type: ';',
					// 	length: '1',
					// 	unit: '',
					// 	attribute: '必选',
					// 	example: ';',
					// 	remarks: '',
					// },{
					// 	title: '内容积',
					// 	type: '数字',
					// 	length: '变长',
					// 	unit: 'm³',
					// 	attribute: '必选',
					// 	example: '32.6',
					// 	remarks: '对于平台集装箱，则设置为0m³。',
					// },{
					// 	title: '分隔符',
					// 	type: ';',
					// 	length: '1',
					// 	unit: '',
					// 	attribute: '必选',
					// 	example: ';',
					// 	remarks: '',
					// },{
					// 	title: '用户自定义区',
					// 	type: '',
					// 	length: '变长',
					// 	unit: '无',
					// 	attribute: '可选',
					// 	example: '',
					// 	remarks: '用户自定义，各项数据之间用半角的分号“;”分隔。',
					// },
				],
				
			}
		},
		computed:{
			
		},
		methods:{
			tableRowClassName({row, rowIndex}) {
				if (rowIndex % 2 != 0) {
					return '';
				}
				return 'default-row';
			},
		},
		mounted() {
			
		}
	}
</script>
<style lang="scss">
	.el-table .default-row {
		background: #f6fafd;
	}
	.el-table .other-row {
		background: #eee;
	}
</style>
<style lang="scss" scoped>
	.codeSpecification_box{
		margin: 10px 0 40px 0;
		width: 100%;
		height: auto;
		.specification_header{
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: center;
			font-size: 26px;
			font-weight: bold;
			border-bottom: 1px solid #eee;
		}
		.table_box{
			width: 100%;
			height: auto;
			.table_cont_box{
				width: 100%;
				margin-top: 20px;
			}
		}
	}
</style>
