<!-- 联盟介绍 -->
<template>
  <div class="con1_page">

      <!-- 关于我们 -->
    <div class="aboutUs">
      <p class="font_30Tit">关于我们</p>
      <!-- <hr /> -->
      <p>
        海洋工程装备行业的工业互联网标识解析二级节点应用服务平台，为海洋工程装备全供应链提供标准和开放的海洋工程装备身份识别服务，实现海洋工程装备标识的自动识别和信息交互：
      </p>

      <p>
        通过对接国家顶级节点和企业节点，为海洋工程装备行业应用企业和用户提供灵活的标识编码注册、标识解析、标识代理、数据管理、业务管理等服务；
      </p>

      <p>
        通过 Handle 标识解析技术为全球流转的每一个海洋工程装备赋予唯一标识，实现工业互联网标识解析在海洋工程装备供应链管理、海洋工程装备全过程追溯管理、海洋工程装备定位追踪管理等场景中的应用，实现海洋工程装备的追溯、追踪与管理等服务；
      </p>

      <p>
        通过海洋工程装备标识自动识别、信息系统间数据的交互与共享，以及对标识数据的分析应用，实现全球环境下海洋工程装备物流全供应链的基本信息的实时与透明，全供应链下各孤立系统之间的数据交互与信息共享，实现海洋工程装备全寿命周期的信息交互与数据管理；
      </p>

      <p>
        通过分析和挖掘二级节点本身产生的大量价值数据， 培育标识设备、标识解析软硬件集成、标识解析应用、标识解析体系运营等全新产业，推动标识产业生态培育，从而成为构建安全、稳定、高效、可靠的工业互联网标识解析体系的重要基础和支撑环节；
      </p>

    </div>

    <p class="font_30Tit">联盟介绍</p>

    <div class="introduce_box">
      <div class="introduce_L">
        <img
          :src="imgUrl+'/unLoginPages/aboutUs/con1_left.png'"
          alt=""
        />
      </div>
      <div class="introduce_R">
        <p class="font_30">中国深远海海洋工程装备技术产业联盟</p>
        <p class="font_20">China Offshore (Deepsea) Industry Alliance</p>
        <div class="introduce_R_txtBox">
          <p>
            中国深远海海洋工程装备技术产业联盟是工信部支持成立的，按照“公司+联盟”的运作模式，以打造协同创新、有序分享的产业生态和国家级创新中心为目标，引领海洋工程装备产业变革的关键性技术研究、前瞻布局产业前沿技术研发的全国联盟组织。
          </p>
          <p>
            联盟成员单位由从事深远海海洋工程产业研究开发、总装制造、设备制造、应用服务等的企事业单位、大专院校、科研机构、用户单位、认证机构和金融机构及其他相关机构等资源组成。
          </p>
        </div>
      </div>
    </div>
    <!-- 联盟宗旨 -->
    <div class="zongZhi">
      <p class="font_30Blue">联盟宗旨</p>
      <hr />
      <p>
        以全球海洋产业发展和市场需求为引导，以企业为主体，以市场为导向，优化资源配置，促进产学研用全产业链融合，促进中国海洋工程领域的国际间交流与合作。
      </p>
    </div>
    <!-- 联盟目标 -->
    <div class="target">
      <p class="font_30Blue">联盟目标</p>
      <hr />
      <div>
        <p class="font_20B">加强行业自律：</p>
        <p class="font_20">联盟内企业相互支持，加强合作，规范自律，培育市场；</p>
        <p class="font_20B">搭建沟通与合作的平台：</p>
        <p class="font_20">构建用户、企业、科研院所、金融机构资源信息共享、合作交流的平台；</p>
        <p class="font_20B">加强产业链协同创新：</p>
        <p class="font_20">瞄准深海海洋工程装备设计制造关键共性技术、系统产品研发、标准规范制定等方面，开展产学研用相关单位联合攻关；</p>
        <p class="font_20B">完善配套产业链：</p>
        <p class="font_20">加强海工装备关键系统和设备的研制，以最终用户带动总装建造和关键系统及配套设备的发展；</p>
        <p class="font_20B">共同参与国际合作：</p>
        <p class="font_20">联合开展深海装备制造的国际交流、合作与对话，积极融入全球产业链，形成良好的国际产业合作发展环境。</p>
      </div>
    </div>

    <!-- 组织架构 -->
    <div class="jiaGou">
        <p class="font_30Blue">组织架构</p>
        <hr/>
        <!-- <img src="../../../../assets/unLoginPages/aboutUs/jiagou.png" alt=""> -->
        <img :src="imgUrl+'/unLoginPages/aboutUs/jiagou.png'" alt="">
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapActions, mapState } from "vuex";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      imgUrl: (state) => state.imgUrl,
    }),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.font_30Blue {
  font-size: 30px;
  font-weight: 600;
  color: #092a5d;
}
.con1_page {
  font-size: 20px; //
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  .aboutUs{
    p{
      text-indent: 2em;
      text-align: left;
    }
  }
  .font_30Tit {
    font-size: 30px;
    color: #888f9a;
    font-weight: 800;
    text-align: center !important;
  }
  .introduce_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    .introduce_L {
      img {
        width: 450px;
      }
    }
    .introduce_R {
      width: calc(100% - 450px);
      .font_20 {
        font-size: 20px;
      }
      .font_30,
      .font_20 {
        color: #092a5d;
        font-weight: 600;
        margin: 0;
      }
      .introduce_R_txtBox {
        text-align: left;
        text-indent: 2em;
        font-size: 20px;
      }
    }
  }
  //
  .zongZhi {
    width: 100%;
  }
  //联盟目标
  .target {
    width: 100%;
    .font_30Blue {
    }
    .font_20B{
        font-weight: 600;
        color: #000;
    }
  }
  //组织架构
  .jiaGou{
      width: 100%;
      img{
          width: 60%;
      }
  }
}
</style>