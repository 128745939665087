<template>
  <div class="index_page_box">
    <img
      class="banner_img"
      :src="imgUrl+'/unLoginPages/indexPage/banner.png'"
    />
    
    <div class="search_container">
      <div class="search_box">
        <div class="search_input">
          <el-input
            class="input_box"
            placeholder="请输入海工装备编号"
            v-model="searchVal"
            clearable
          ></el-input>
        </div>
        <div class="search_btn">
          <i class="el-icon-search"></i>
          <span class="marginLeft" @click="handleSearch">查看海工装备</span>
          <div class="tips_text" @click="handleShowCode">扫码通过微信小程序查看装备信息</div>
        </div>
      </div>
    </div>
    <div class="cont_item_box">
      <div class="title_box">丰富的产品种类</div>
      <div class="title_script_text">
        通过持续创新实现装备与运营管理的智能化，为客户带来价值、赢得利益，成为客户依赖的伙伴
      </div>
      <div class="main_content_box">
        <div class="main_item_box">
          <div class="item_cont_box">
            <img
              class="icon_img"
              src="../../../assets/unLoginPages/indexPage/icon.png"
            />
          </div>
          <div class="item_cont_box2">
            <div class="item_title">海工装备二维码</div>
            <div class="iotem_text">自研图像识别算法，智能识别</div>
          </div>
        </div>
        <div class="main_item_box">
          <div class="item_cont_box">
            <img
              class="icon_img"
              src="../../../assets/unLoginPages/indexPage/icon.png"
            />
          </div>
          <div class="item_cont_box2">
            <div class="item_title">海工装备标识解析</div>
            <div class="iotem_text">
              快速解析海工装备信息，及时、准确掌握相关动态信息
            </div>
          </div>
        </div>
        <div class="main_item_box">
          <div class="item_cont_box">
            <img
              class="icon_img"
              src="../../../assets/unLoginPages/indexPage/icon.png"
            />
          </div>
          <div class="item_cont_box2">
            <div class="item_title">海工装备二维码</div>
            <div class="iotem_text">
              打印装备身份证(二维码)，一证通行，畅行无阻
            </div>
          </div>
        </div>
        <div class="main_item_box">
          <div class="item_cont_box">
            <img
              class="icon_img"
              src="../../../assets/unLoginPages/indexPage/icon.png"
            />
          </div>
          <div class="item_cont_box2">
            <div class="item_title">EDI对接</div>
            <div class="iotem_text">数据平台联通，快速EDI对接</div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="cont_item_box solveScheme">
      <div class="title_box">行业解决方案</div>
      <div class="title_script_text">
        面向海工装备行业提供更先进的解决方案，与企业精准匹配，打造二级节点行业级应用
      </div>
      
      <!-- <el-carousel :interval="2000000" arrow="always" indicator-position="outside">
        <el-carousel-item v-for="item in lunboList" :key="item.tit">
          <div class="lunbo_bigBox">
            <div class="lunbo_title"><h2><strong>{{item.tit}}</strong></h2></div>
            <div class="lunbo_box">
              <div class="lunbo_Left">
                <p>{{item.text}}</p>
              </div>
              <div class="lunbo_Right"><img :src="imgUrl+''+item.img" alt=""></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->

<!-- 轮播 -->
  <el-carousel indicator-position="outside">
    <el-carousel-item v-for="(item,index) in lunboList" :key="index">
      <img :src="item.img" alt="" class="lunbo_img">
    </el-carousel-item>
  </el-carousel>





      <!-- <el-carousel :interval="4000" type="card" height="200px">
        <el-carousel-item v-for="item in 6" :key="item">
          <div class="lunbo_bigBox">
            <div class="lunbo_title"><h2><strong>物资供应链</strong></h2></div>
            <div class="lunbo_box">
              <div class="lunbo_Left">
                <p>以海工装备物资及订单为标识对象，赋予唯一标识编码并注册到海工装备二级节点，供应商通过工业互联网标识解析平台解析，可快速查询到海工装备物资采购订单信息，包括订单号、物资名称、型号、数量、采购员、采购日期等；总装企业管理人员、仓库管理、质检以及船东通过工业互联网标识解析平台解析，可快速查询到物资产品属性及状态信息，解决了库存成本高，采购周期长，各流通环节中信息交流和协调不及时，实现了供应链总成本的最低。</p>
              </div>
              <div class="lunbo_Right"><img src="../../../assets/unLoginPages/indexPage/lunbo1.png" alt=""></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->
      <!-- <div class="main_content_box2">
        <div class="cont_box">
          <div class="cont_img_box">
            <img
              class="solution_img"
              :src="imgUrl+'/unLoginPages/indexPage/solution_01.png'"
            />
            <div class="main_cont">
              <img
                class="main_cont_img"
                src="../../../assets/unLoginPages/indexPage/icon_01.png"
              />
              <span>xxx解决方案</span>
            </div>
          </div>
        </div>
        <div class="cont_box">
          <div class="cont_img_box">
            <img
              class="solution_img"
              :src="imgUrl+'/unLoginPages/indexPage/solution_02.png'"
            />
            <div class="main_cont">
              <img
                class="main_cont_img"
                src="../../../assets/unLoginPages/indexPage/icon_02.png"
              />
              <span>xxx解决方案</span>
            </div>
          </div>
        </div>
        <div class="cont_box">
          <div class="cont_img_box">
            <img
              class="solution_img"
              :src="imgUrl+'/unLoginPages/indexPage/solution_03.png'"
            />
            <div class="main_cont">
              <img
                class="main_cont_img"
                src="../../../assets/unLoginPages/indexPage/icon_03.png"
              />
              <span>xxx解决方案</span>
            </div>
          </div>
        </div>
        <div class="cont_box">
          <div class="cont_img_box">
            <img
              class="solution_img"
             :src="imgUrl+'/unLoginPages/indexPage/solution_04.png'"
            />
            <div class="main_cont">
              <img
                class="main_cont_img"
                src="../../../assets/unLoginPages/indexPage/icon_04.png"
              />
              <span>xxx解决方案</span>
            </div>
          </div>
        </div>
        <div class="cont_box">
          <div class="cont_img_box">
            <img
              class="solution_img"
              :src="imgUrl+'/unLoginPages/indexPage/solution_05.png'"
            />
            <div class="main_cont">
              <img
                class="main_cont_img"
                src="../../../assets/unLoginPages/indexPage/icon_05.png"
              />
              <span>xxx解决方案</span>
            </div>
          </div>
        </div>
      </div> -->

    </div>


    <!-- 暂时隐藏 -->
    <!-- <div class="bg_img_box">
      
      
      <img
        class="bg33_img"
        :src="imgUrl+'/unLoginPages/indexPage/bg33.png'"
      />
      <div class="industry_box">
        <div class="title_box">多行业领域客户的共同信赖</div>
        <div class="title_script_text">
          面向海工装备行业提供更先进的解决方案，与企业精准匹配，打造二级节点行业级应用
        </div>
        <div class="industry_content_box">
          <el-tabs tab-position="left" class="industry_item">
            <el-tab-pane>
              <div slot="label">
                <div class="label_box">
                  <div>xxx行业</div>
                  <div class="label_script">丰富的设备对接经验</div>
                </div>
              </div>
              <div class="industry_cont">
                <div class="industry_title">采集与控制</div>
                <div class="industry_script_box">
                  <div class="industry_script">
                    智能物联网拥有完全自主研发的数据采集产品，采用可编程方式设计，适用性广，已成功对接超过80+种工业设备，
                    完成20万+台设备的数据采集工作。
                  </div>
                  <div class="industry_script">
                    帮助工业企业实现从传统的工厂信息化到云端远程信息化的跨越，能够更高效的进行设计设备监控管理，生产资源
                    调度，大大提高工业应用的运营效率，向智能制造迈进。
                  </div>
                </div>
                <div class="more_btn">查看详情</div>
                <div class="solution_box">
                  <div class="solution_text">相关解决方案</div>
                  <div class="solution_cont">
                    <div class="solution_btn">xxx解决方案</div>
                    <div class="solution_btn">xxx解决方案</div>
                    <div class="solution_btn">xxx解决方案</div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <div slot="label">
                <div class="label_box">
                  <div>xxx行业</div>
                  <div class="label_script">丰富的设备对接经验</div>
                </div>
              </div>
              <div class="industry_cont">行业2</div>
            </el-tab-pane>
            <el-tab-pane>
              <div slot="label">
                <div class="label_box">
                  <div>xxx行业</div>
                  <div class="label_script">丰富的设备对接经验</div>
                </div>
              </div>
              <div class="industry_cont">行业3</div>
            </el-tab-pane>
            <el-tab-pane>
              <div slot="label">
                <div class="label_box">
                  <div>xxx行业</div>
                  <div class="label_script">丰富的设备对接经验</div>
                </div>
              </div>
              <div class="industry_cont">行业4</div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div> -->
    <div class="cont_item_box">
      <div class="title_box">海工装备二维码</div>
      <div class="title_script_text">
        <div>
          二维码和移动互联的普及为海工装备标识的自动识别提供了新的手段，由于二维码的成本极低，使用寿命与海工装备等同
        </div>
        <div>不存在电子设备需要定期更换电池等维护成本高的问题</div>
        <div>
          从而解决了海工装备标识自动识别的系统成本、产品成本、可靠性、维护成本等一些列问题
        </div>
      </div>
      <div class="code_content_box">
        <img
          class="code_img"
          :src="imgUrl+'/unLoginPages/indexPage/img.png'"
        />
      </div>
    </div>
    <div class="api_img_box">
      <img
        class="api_img"
        :src="imgUrl+'/unLoginPages/indexPage/vmApi-banner.png'"
      />
      <div class="industry_box">
        <div class="title_box">开放API接口</div>
        <div class="title_script_text">
          <div>
            通过API可快速便捷的管理云资源。可将云计算资源整合入自身运维管理系统，满足上层应用的动态调度和服务售卖。
          </div>
          <div>大幅提高云计算资源的管理效率。</div>
        </div>
        <div class="industry_content_box"></div>
      </div>
    </div>
    <div class="cont_item_box">
      <div class="title_box">来自多方媒体的支持和认可</div>
      <div class="title_script_text">
        为您提供行业质询、活动公共、产品发布、以及汇聚前沿的云计算技术
      </div>
      <div class="media_cont_box">
        <div class="media_item_box">
          <img
            class="news_img"
            :src="imgUrl+'/unLoginPages/indexPage/news_01.png'"
          />
          <div class="media_text_box">
            团队根据我们的实际情况，协助制定了完整的数据分析平台、大数据平台以及数据中心建设的总体规划
          </div>
          <div class="show_more_box">查看更多</div>
          <div class="dataTime_box">
            <div class="data_text">30</div>
            <div>2021/08</div>
          </div>
        </div>
        <div class="media_item_box">
          <img
            class="news_img"
            :src="imgUrl+'/unLoginPages/indexPage/news_02.png'"
          />
          <div class="media_text_box">
            团队根据我们的实际情况，协助制定了完整的数据分析平台、大数据平台以及数据中心建设的总体规划
          </div>
          <div class="show_more_box">查看更多</div>
          <div class="dataTime_box">
            <div class="data_text">30</div>
            <div>2021/08</div>
          </div>
        </div>
        <div class="media_item_box">
          <img
            class="news_img"
            :src="imgUrl+'/unLoginPages/indexPage/news_03.png'"
          />
          <div class="media_text_box">
            团队根据我们的实际情况，协助制定了完整的数据分析平台、大数据平台以及数据中心建设的总体规划
          </div>
          <div class="show_more_box">查看更多</div>
          <div class="dataTime_box">
            <div class="data_text">30</div>
            <div>2021/08</div>
          </div>
        </div>
      </div>
    </div>
    <div class="partner_box">
      <div class="partner_title_box">
        <div class="title_box">合作伙伴</div>
        <div class="title_script_text">来自知名企业、机构的信任和选择</div>
      </div>
      <div class="partner_cont_box">
        <div class="partner_cont">
          <div class="partner_item">
            <img
              class="partner_img"
              src="../../../assets/unLoginPages/indexPage/partner_01.png"
            />
            <span>箱行天下</span>
          </div>
          <div class="partner_item">
            <img
              class="partner_img"
              src="../../../assets/unLoginPages/indexPage/partner_02.png"
            />
            <span>罐通天下</span>
          </div>
          <div class="partner_item">
            <img
              class="partner_img"
              src="../../../assets/unLoginPages/indexPage/partner_03.png"
            />
            <span>冷链天下</span>
          </div>
        </div>
      </div>
    </div>
    <div class="partner_box">
      <div class="partner_title_box">
        <div class="title_box">联系我们</div>
        <div class="title_script_text">
          专业高效、不断探索、追求创新，给您一个安全可靠的肩膀
        </div>
      </div>
      <div class="partner_cont_box">
        <img
          class="map_img"
          :src="imgUrl+'/unLoginPages/indexPage/map.png'"
        />
      </div>
    </div>
		<!-- 二维码生成 -->
		<el-dialog
		  title="微信扫码查看装备信息"
		  :visible.sync="showQRcodeModel"
		  width="800px"
			:lock-scroll="false">
		  <div class="dialog_content_box">
		    <UnloginQRcode v-if="showQRcodeModel" ref="QRcodeRef"></UnloginQRcode>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapState, mapMutations} from "vuex";
import axios from "axios";
import UnloginQRcode from './unloginQRcode.vue'
export default {
	components:{
		UnloginQRcode,
	},
  data() {
    return {
      searchVal: "",
			showQRcodeModel:false,
      // img:require("../../../assets/unLoginPages/indexPage/lunbo1.png"),
      // lunboList:[
      //   {tit:'物资供应链',text:`以海工装备物资及订单为标识对象，赋予唯一标识编码并注
      //   册到海工装备二级节点，供应商通过工业互联网标识解析平台解析，可快速查询到海工
      //   装备物资采购订单信息，包括订单号、物资名称、型号、数量、采购员、采购日期等；
      //   总装企业管理人员、仓库管理、质检以及船东通过工业互联网标识解析平台解析，可快
      //   速查询到物资产品属性及状态信息，解决了库存成本高，采购周期长，各流通环节中信
      //   息交流和协调不及时，实现了供应链总成本的最低。`,img:'/unLoginPages/indexPage/lunbo1.png'},
      //   {tit:'产品追溯',text:`以钢材为标识对象，赋予唯一标识编码并注册到海工装备二级节点，在其流通及生产制造过程中，通过工
      //   业互联网标识解析平台解析，可追溯到产品属性信息，包括批次号、重量、厚度、船级社、材质、炉批号、证书号等，解决了产
      //   品在流通及生产过程中因不同系统间相互独立，需手工进行维护数据，实现了数据的共享、共用。`,img:'/unLoginPages/indexPage/lunbo2.png'},
      //   {tit:'设备保养',text:`以海工装备设备、保养报告等为标识对象，赋予唯一标识编码并注册到海工装备二级节点，当供应商、
      //   技术人员、项目管理人员、维护人员、船东、质检人员等对产品属性、状态、保养报告、维护负责人等信息进行查询及确认时，
      //   通过工业互联网标识解析平台解析后，可快速获取到相关信息，并实时更新保养状态，实现了数据的互联互通，共享、共用。`,img:'/unLoginPages/indexPage/lunbo3.png'},
      //   {tit:'船岸协同',text:`以海工平台、总装企业船坞等为标识对象，赋予唯一标识编码并注册到海工装备二级节点，当总装企业
      //   、设计院及船东等相关人员对海工平台的归属、名称、状态和船坞的属性、状态进行查询及确认时，通过工业互联网标识解析平
      //   台解析后，可快速获取到相关信息，实现了数据的互联互通，共享、共用。`,img:'/unLoginPages/indexPage/lunbo4.png'},
      // ]
      lunboList:[
        {img:require("../../../assets/unLoginPages/indexPage/banner01.jpg")},
        {img:require("../../../assets/unLoginPages/indexPage/banner02.jpg")},
        {img:require("../../../assets/unLoginPages/indexPage/banner03.jpg")},
        {img:require("../../../assets/unLoginPages/indexPage/banner04.jpg")},
      ]
    };
  },
  computed:{
			...mapState({
				imgUrl: state => state.imgUrl,
			}),
		},
  methods: {
		...mapMutations({
			setActiveIndex:'setActiveIndex',
		}),
    handleSearch() {
      if (this.searchVal == "") {
        this.$alert('请先输入装备编号后，通过微信扫描查看装备信息', '提示', {
          confirmButtonText: '确定'
        });
        return false;
      }
			this.setActiveIndex('/equipmentQuery');
			this.$router.replace({path:'/equipmentQuery',query: {searchVal:this.searchVal}});
    },
		handleShowCode(){
			if (this.searchVal == "") {
			  this.$alert('请先输入装备编号后，通过微信扫描查看装备信息', '提示', {
          confirmButtonText: '确定'
        });
			  return false;
			}
			this.showQRcodeModel = true;
			this.$nextTick(()=>{
				this.$refs.QRcodeRef.init(this.searchVal);
			})
		},
  },
  mounted() {
		// console.log(this.imgUrl);
	},
};
</script>

<style lang="scss" scoped>
.index_page_box {
  width: 100%;
  height: auto;
  background-color: #f6fafd;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .banner_img {
    width: 100%;
    height: 50vh;
  }
  .search_container {
    width: 100%;
    height: 84px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .search_box {
      width: $heightBaseContent;
      height: 126px;
      box-shadow: 0 6px 6px #deebfb;
      position: absolute;
      top: -60px;
      background-color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 28px 46px 48px 46px;
      .search_input {
        width: calc(100% - 220px);
        height: 100%;
        border: 1px solid #ebeaee;
        box-sizing: border-box;
        border-right: none;
        .input_box {
          height: 100%;
        }
      }
      .search_btn {
        width: 220px;
        height: 100%;
        background-color: $theam-color;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        position: relative;
        .marginLeft {
          margin-left: 10px;
          font-size: $base-font-size;
          padding-bottom: 4px;
        }
        .tips_text {
          width: auto;
          height: auto;
          position: absolute;
          bottom: -30px;
          color: $theam-color;
          font-size: $mini-font-size;
        }
      }
    }
  }
  .cont_item_box {
    width: $heightBaseContent;
    height: auto;
    box-sizing: border-box;
    padding-top: 60px;
    .title_box {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      color: $title-font-color;
    }
    .title_script_text {
      min-height: 60px;
      text-align: center;
      line-height: 32px;
    }
    .main_content_box {
      width: 100%;
      height: 360px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .main_item_box:hover {
        box-shadow: 0 0 10px #deebfb;
        transform: scale(1.1);
      }
      .main_item_box {
        width: calc(25% - 36px);
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;
        border-top: 4px solid $theam-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-out;
        .item_cont_box {
          width: 100%;
          height: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .icon_img {
          width: 79px;
          height: 90px;
        }
        .item_cont_box2 {
          width: 100%;
          height: 50%;
          text-align: center;
          line-height: 32px;
        }
        .item_title {
          font-size: 20px;
          color: #f9a42f;
          margin-bottom: 10px;
        }
        .iotem_text {
          width: 100%;
          height: auto;
          box-sizing: border-box;
          padding: 0 40px;
        }
      }
    }
    .main_content_box2 {
      width: 100%;
      height: 360px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .cont_box {
        width: 20%;
        height: 100%;
        position: relative;
        cursor: pointer;
        .cont_img_box:hover {
          transform: scale(1.1);
          z-index: 2;
        }
        .cont_img_box {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          transition: all 0.2s ease-out;
        }
        .solution_img {
          width: 100%;
          height: 100%;
        }
        .main_cont {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
          .main_cont_img {
            width: 64px;
            height: 56px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .code_content_box {
      width: 100%;
      height: 460px;
      box-sizing: border-box;
      padding-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .code_img {
        height: 100%;
      }
    }
    .media_cont_box {
      width: 100%;
      height: 410px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .media_item_box {
        width: calc(33% - 80px);
        height: 100%;
        position: relative;
        .news_img {
          width: 100%;
        }
        .media_text_box {
          width: 100%;
          height: 52;
          margin-top: 30px;
          line-height: 26px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          color: #999;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .show_more_box {
          width: 140px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          right: 0;
          border: 1px solid #999;
          box-sizing: border-box;
          cursor: pointer;
        }
        .show_more_box:hover {
          color: #fff;
          border: none;
          background-color: $theam-color;
        }
        .dataTime_box {
          padding: 8px 4px;
          background-color: $theam-color;
          color: #fff;
          position: absolute;
          top: -10px;
          right: -10px;
          text-align: center;
          .data_text {
            font-size: 26px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .bg_img_box {
    width: 100%;
    height: 600px;
    position: relative;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    .bg33_img {
      width: 100%;
      height: 100%;
    }
    .industry_box {
      width: $heightBaseContent;
      height: 100%;
      box-sizing: border-box;
      padding: 20px 0 40px 0;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      color: #fff;
      .title_box {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
      }
      .title_script_text {
        height: 60px;
        text-align: center;
        color: #ddd;
      }
      .industry_content_box {
        width: 100%;
        height: calc(100% - 120px);
        .industry_item {
          width: 100%;
          height: 100%;
          .label_box {
            height: 70px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }
          .label_script {
            font-size: $mini-font-size;
            color: #ccc;
          }
          .industry_cont {
            width: auto;
            height: 420px;
            box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 120px;
            color: #ddd;
            .industry_title {
              font-size: 24px;
              color: #fff;
            }
            .industry_script_box {
              padding: 40px 0;
              line-height: 26px;
            }
            .more_btn {
              width: 120px;
              height: 30px;
              border: 1px solid #ddd;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
            .solution_box {
              width: 100%;
              height: auto;
              margin-top: 40px;
              .solution_text {
                width: 100%;
                height: 40px;
                border-bottom: 1px solid #ddd;
              }
              .solution_cont {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                padding: 20px 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .solution_btn {
                  padding: 5px 26px;
                  margin-right: 20px;
                  box-sizing: border-box;
                  border: 1px solid #ddd;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  background-color: #202430;
                }
              }
            }
          }
        }
      }
    }
  }
  .api_img_box {
    width: 100%;
    height: 320px;
    position: relative;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    .api_img {
      width: 100%;
      height: 100%;
    }
    .industry_box {
      width: $heightBaseContent;
      height: 100%;
      box-sizing: border-box;
      padding: 20px 0 40px 0;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      color: #fff;
      .title_box {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
      }
      .title_script_text {
        height: 60px;
        text-align: center;
        line-height: 32px;
        color: #ddd;
      }
    }
  }
  .partner_box {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .partner_title_box {
      width: $heightBaseContent;
      height: auto;
      box-sizing: border-box;
      padding-top: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title_box {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
      }
      .title_script_text {
        height: 60px;
        text-align: center;
        line-height: 32px;
      }
    }
    .partner_cont_box {
      width: 100%;
      height: 300px;
      background-color: #f3f6fd;
      display: flex;
      align-items: center;
      justify-content: center;
      .partner_cont {
        width: $heightBaseContent;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        padding: 0 40px;
        .partner_item {
          width: 102px;
          height: 102px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .partner_img {
            width: 102px;
            margin-bottom: 10px;
          }
        }
      }
      .map_img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

</style>

<style lang="scss">

/* // 轮播 */
.solveScheme{
  height: 800px !important;
  // border:springgreen 1px solid;
}
.lunbo_title{
  text-align: center;
  margin-top: 20px;
  // border: 1px solid;
  // background: red;
  box-sizing: border-box;
  padding-top: 20px;
}
.lunbo_bigBox{
    height: 600px !important;
    // background: rgba(0, 0, 255, 0.192);
    background: #fff;
    border-radius:30px; 

.lunbo_box{
    display: flex;
    align-items: center;
    align-content: space-around;
    justify-content: space-between;
    // border: 1px solid red;
    // background: #fff;
    box-sizing: border-box;
    padding: 20px;
    padding-top: 0;
    .lunbo_Left{
      text-indent: 2em;
      font-size: 20px;
      line-height: 48px;
      color: #666;
      width: 45%;
    }
    .lunbo_Right{
      width: 45%;
      box-sizing: border-box;
      padding: 20px 0;
    img{
      width: 100%;
    }
    }
  }
}





  .el-carousel__container {
    position: relative;
    height: 580px !important;
    // border: 1px solid rebeccapurple;
    border-radius:30px !important ;
}


.el-carousel{
    border-radius: 30px;
}
  .el-carousel__item{
    border-radius: 30px;
  }
  .el-carousel__arrow{
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
  .lunbo_img{
    width: 100%;
    border-radius: 30px;
  }
</style>
