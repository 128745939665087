<template>
  <div class="equipmentQuery_box">
    <iframe
		id="map"
      ref="map"
      :src="baseUrl + 'pages/map.html'"
      frameborder="0"
      height="100%"
      width="100%"
      scrolling="auto"
    ></iframe>
  </div>
</template>

<script>
export default {
  data(){
		return {
			baseUrl: process.env.BASE_URL, //基础url
      		searchVal:'',//接收首页input传递过来的值
		}
	},
	methods:{
		registrationWinEvents(){
			window["handleVueFunction"] = (val) => {	//首页查看海工装备
				if(this.searchVal){
					this.$refs.map.contentWindow.setInputVal(this.searchVal);
					this.$refs.map.contentWindow.select(this.searchVal);
				}
			};
		},
	},
  mounted() {
	if(this.$route.query.searchVal){
		this.searchVal = this.$route.query.searchVal;
	}
    this.registrationWinEvents();	
  },
};
</script>
<style type="text/css">
.equipmentQuery_box {
  height: calc(100vh - 60px);
  width: 100%;
}
</style>
