<template>
  <div class="aboutUs_box">
    <!-- 关于我们 -->
    <div id="box_top">
      <div>
        <!-- <img class="banner_img" :src="imgUrl+'/unLoginPages/aboutUs/aboutUsBg.jpg'" />  -->
        <img
          class="banner_img"
          :src="imgUrl+'/unLoginPages/aboutUs/banner.jpg'"
        />
      </div>
    </div>

    <div class="partnerNav">
      <el-tabs v-model="activeName" class="tabBox">
        <el-tab-pane label="联盟介绍" name="first">
          <Con1></Con1>
        </el-tab-pane>

        <el-tab-pane label="联盟章程" name="second">
          <Con2></Con2>
        </el-tab-pane>

        <!-- <el-tab-pane label="联盟成员" name="third">
          <Con3></Con3>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Con1 from "./aboutComponents/con1.vue";
import Con2 from "./aboutComponents/con2.vue";
import Con3 from "./aboutComponents/con3.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Con1,
    Con2,
    Con3,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  computed: {
    ...mapState({
      imgUrl: (state) => state.imgUrl,
    }),
  },
  methods: {
    // handleClick(tab, event) {
    //     console.log(tab, event);
    //   }
  },
  mounted() {
    function change() {
      var Root = document.documentElement;
      Root.style.fontSize = window.innerWidth / 192 + "px";
      // console.log(Root.style.fontSize);//默认10px
    }
    change();
    window.onresize = function () {
      console.log("浏览器窗口的大小被改变了");
      change();
    };
    // console.log(window.innerWidth);
    // document.documentElement.style.fontSize=10+'px'
    // console.log(document.documentElement.style.fontSize);
  },
};
</script>




<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.box_top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.aboutUs_box {
  width: 100%;
  height: 100%;
  // flex-direction: column;
  min-height: calc(100vh - 320px);
}
.banner_img {
  // position: relative;
  // display: inline-block;
  // overflow: hidden;
  //   vertical-align: top;
  width: 100%;
}

//导航
.partnerNav {
  //   height: 0.46875rem; //90
  // border-bottom: 1px solid #e4e8f3;
  width: 100%;
  .tabBox {
    // width: 100%;
    // display: flex;
    // justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  }
}

</style>

<style>
.partnerNav .is-top::after {
  background-color: #fff;
}
</style>
