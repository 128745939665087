<!--  -->
<template>
  <div class="openInterface_page_box">
		<div class="openBox">
		  <div id="nvaBox">
		    <ul class="nav_ul" lay-filter="api">
		      <li class="nav_li">
		        <a href="#p_href-1" title="1.企业获取登录凭证接口"
		          >1.企业获取登录凭证接口</a
		        >
		      </li>
		      <li class="nav_li">
		        <a href="#p_href-2" title="2.模板上传接口">2.模板上传接口</a>
		      </li>
		      <li class="nav_li">
		        <a href="#p_href-3" title="3.模板查看接口">3.模板查看接口</a>
		      </li>
		      <li class="nav_li">
		        <a href="#p_href-4" title="4.模板修改接口">4.模板修改接口</a>
		      </li>
		      <li class="nav_li">
		        <a href="#p_href-5" title="5.模板删除接口">5.模板删除接口</a>
		      </li>
		      <li class="nav_li">
		        <a href="#p_href-6" title="6.物料新增接口">6.物料新增接口</a>
		      </li>
		      <li class="nav_li">
		        <a href="#p_href-7" title="7.物料查看接口">7.物料查看接口</a>
		      </li>
		      <li class="nav_li">
		        <a href="#p_href-8" title="8.物料删除接口">8.物料删除接口</a>
		      </li>
		
		      <li class="nav_li">
		        <a href="#p_href-9" title="9.下载说明书">9.下载说明书</a>
		      </li>
		    </ul>
		  </div>
		  <div class="tabBox">
		    <div class="content">
		      <h2><a name="p_href-1">1.企业获取登录凭证接口</a></h2>
		      <table class="table">
		        <tr>
		          <td>内容</td>
		          <td>
		            <p>企业通过登录二级节点获取登录凭证token</p>
		          </td>
		        </tr>
		        <tr>
		          <td>URL</td>
		          <td>{{baseURL}}/codia/company/accountLogin</td>
		        </tr>
		        <tr>
		          <td>提交方式(post)</td>
		          <td>Post</td>
		        </tr>
		        <tr>
		          <td>重要字段解释</td>
		          <td>{username}代表用户名，{password}代表用户密码</td>
		        </tr>
		        <tr>
		          <td>http原始报文</td>
		          <td>
		            <p>POST /codia/company/accountLogin HTTP/1.1</p>
		            <p>Host: 127.0.0.1:3000</p>
		            <p>Content-Type: application/json</p>
		            <p>Content-Length: 206</p>
		            <p>
		              {"authenticationString":"/Yv55KTrPMJyi3gSHPlKvnrMI64h/fdh4YFquMBN3Z61l9G7wFm0n+27aaSOKr1f1"}
		            </p>
		          </td>
		        </tr>
		
		        <tr>
		          <td>原始报文解释</td>
		          <td>
		            <p>
		              1.Content-Type为application/json,模板数据以json格式放在请求体中.
		            </p>
		            2.请求体中authenticationString的值为以AES
		            PKCS5Padding加密的json字符串{"username":"???","password":"???"}(问号替换为对应登录账号密码)
		            <p>密钥key：cimcitech_123456</p>
		            <p>偏移量iv：cimcitech_123456</p>
		          </td>
		        </tr>
		        <tr>
		          <td>返回结果</td>
		          <td>
		            <table class="table">
		              <tr>
		                <td>errorCode:</td>
		                <td>200</td>
		              </tr>
		
		              <tr>
		                <td>message:</td>
		                <td>"请求成功"</td>
		              </tr>
		
		              <tr>
		                <td>data:</td>
		                <td>{</td>
		              </tr>
		              <tr>
		                <td>userInfo:</td>
		                <td>{</td>
		              </tr>
		              <tr>
		                <td>id:</td>
		                <td>95bcf6d3cef6498c95ea8b67cb2b5931</td>
		              </tr>
		              <tr>
		                <td>nickname:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>logourl:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>username:</td>
		                <td>admin</td>
		              </tr>
		              <tr>
		                <td>password:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>companyName:</td>
		                <td>中集智能</td>
		              </tr>
		              <tr>
		                <td>companyType:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>socialCreditCode:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>industryType:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>registeredAddress:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>companyAddress:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>companyTelephone:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>businessLicenseUrl:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>officialWebsite:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>introduce:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>corporationName:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>corporationId:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>corporationIdPhoto:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>contacts:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>contactsPhone:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>contactsEmail:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>contactsId:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>contactsIdPhoto:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>companyCode:</td>
		                <td>127</td>
		              </tr>
		              <tr>
		                <td>licenseUrl:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>defaultLanguage:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>loginTime:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>addUser:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>addTime:</td>
		                <td>2021-08-02 11:14:48</td>
		              </tr>
		              <tr>
		                <td>updateUser:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>updateTime:</td>
		                <td>2021-09-09 11:13:09</td>
		              </tr>
		              <tr>
		                <td>applicationTime:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>roleId:</td>
		                <td>0</td>
		              </tr>
		              <tr>
		                <td>userStatus:</td>
		                <td>0</td>
		              </tr>
		              <tr>
		                <td>isDeleted:</td>
		                <td>false</td>
		              </tr>
		              <tr>
		                <td></td>
		                <td>}</td>
		              </tr>
		              <tr>
		                <td>token</td>
		                <td>
		                  eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijk1YmNmNmQzY2
		                  VmNjQ5OGM5NWVhOGI2N2NiMmI1OTMxIiwiZXhwIjoxNjMyMzY2OTkxLCJpYXQ
		                  iOjE2MzIzNjMzOTF9.Ggk2R8dfNw1myBAtDkORMJQ25RtX13hBlNo-uD09o8E
		                </td>
		              </tr>
		              <tr>
		                <td></td>
		                <td>}</td>
		              </tr>
		              <tr>
		                <td>success</td>
		                <td>true</td>
		              </tr>
		            </table>
		          </td>
		        </tr>
		      </table>
		    </div>
		
		    <div class="content">
		      <h2><a name="p_href-2">2.海工平台模板上传接口</a></h2>
		      <table class="table">
		        <tr>
		          <td>内容</td>
		          <td>
		            平台支持多种子系统上传物料信息,但由于不同物料信息字段不同需要提前上传将要上传数据的字段模板.
		          </td>
		        </tr>
		        <tr>
		          <td>URL</td>
		          <td>{{baseURL}}/codia/template/uploadTemplate</td>
		        </tr>
		        <tr>
		          <td>提交方式</td>
		          <td>Post</td>
		        </tr>
		        <tr>
		          <td>重要字段解释</td>
		          <td>
		            每个模板的唯一标识为{templateName}字段,该字段也对应着模板的类型名称(物料上传的contentType字段)。templateInfo则以json格式存储了模板各个字段的字段名{field}和中文名{name}。
		          </td>
		        </tr>
		        <tr>
		          <td>http原始报文</td>
		          <td>
		            <table class="table">
		              <tr>
		                <td></td>
		                <td>POST /codia/template/uploadTemplate HTTP/1.1</td>
		              </tr>
		              <tr>
		                <td>Host:</td>
		                <td>127.0.0.1:3000</td>
		              </tr>
		              <tr>
		                <td>token:</td>
		                <td>
		                  <span
		                    >eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijk1YmNmNmQzY2
		                    VmNjQ5OGM5NWVhOGI2N2NiMmI1OTMxIiwiZXhwIjoxNjMxMTc1NjE5LCJpYX
		                    QiOjE2MzExNzIwMTl9.ID2nXCIj4MbMYX4yN_hmhU2AD1A0oKELbZazT3m0dfB</span
		                  >
		                </td>
		              </tr>
		              <tr>
		                <td>Content-Type:</td>
		                <td>application/json</td>
		              </tr>
		              <tr>
		                <td>Content-Length:</td>
		                <td>193</td>
		              </tr>
		            </table>
		
		            {"templateTitle":"测试模板1","templateDescribe":"测试模板的文字描述","templateName":"commonTemplate11","templateInfo":[{"index":1,"field":"matest","name":"测试1"},{"index":2,"field":"matest01","name":"测试2"}]}
		          </td>
		        </tr>
		        <tr>
		          <td>原始报文解释</td>
		          <td>
		            1.请求头中添加token(从企业登录接口获取).
		            2.Content-Type为application/json,模板数据以json格式放在请求体中.
		          </td>
		        </tr>
		
		        <tr>
		          <td>返回结果</td>
		          <td>
		            <table class="table">
		              <tr>
		                <td>errorCode:</td>
		                <td>200</td>
		              </tr>
		              <tr>
		                <td>message:</td>
		                <td>"添加成功"</td>
		              </tr>
		              <tr>
		                <td>data:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>success:</td>
		                <td>true</td>
		              </tr>
		            </table>
		          </td>
		        </tr>
		      </table>
		    </div>
		    <div class="content">
		      <h2><a name="p_href-3">3.海工平台模板查看接口</a></h2>
		      <table class="table">
		        <tr>
		          <td>内容</td>
		          <td>子系统可以通过contentType查看模板</td>
		        </tr>
		        <tr>
		          <td>URL</td>
		          <td>{{baseURL}}/codia/template/selectTemplate</td>
		        </tr>
		        <tr>
		          <td>提交方式(Method)</td>
		          <td>Post</td>
		        </tr>
		        <tr>
		          <td>重要字段解释</td>
		          <td>{contentType}代表模板类型，值为templateName</td>
		        </tr>
		        <tr>
		          <td>http原始报文</td>
		          <td>
		            <p>POST /codia/template/selectTemplate</p>
		            <p>Host: 127.0.0.1:3000</p>
		            <p>Content-Type: application/json</p>
		            <p>Content-Length: 34</p>
		            <p>{"contentType":"commonTemplate11"}</p>
		          </td>
		        </tr>
		
		        <tr>
		          <td>原始报文解释</td>
		          <td>
		            Content-Type为application/json,模板数据以json格式放在请求体中
		          </td>
		        </tr>
		
		        <tr>
		          <td>返回结果</td>
		          <td>
		            <table class="table">
		              <tr>
		                <td>errorCode:</td>
		                <td>200</td>
		              </tr>
		              <tr>
		                <td>message:</td>
		                <td>"请求成功"</td>
		              </tr>
		              <tr>
		                <td>data:</td>
		                <td>{</td>
		              </tr>
		              <tr>
		                <td>id</td>
		                <td>3081d510c98a4ad89a22ee5a6a837505</td>
		              </tr>
		              <tr>
		                <td>templateName:</td>
		                <td>commonTemplate11</td>
		              </tr>
		              <tr>
		                <td>templateInfo:</td>
		                <td>
		                  "[{\"field\":\"matest\",\"index\":1,\"name\":\"测试1\"},{\"field\":\"matest01\",\"index\":2,\"name\":\"测试2\"}]"
		                </td>
		              </tr>
		              <tr>
		                <td>templateTitle:</td>
		                <td>测试模板1</td>
		              </tr>
		              <tr>
		                <td>templateDescribe:</td>
		                <td>测试模板的文字描述</td>
		              </tr>
		              <tr>
		                <td>addTime</td>
		                <td>2021-09-22 14:56:18</td>
		              </tr>
		              <tr>
		                <td>updateTime</td>
		                <td>2021-09-22 14:56:18</td>
		              </tr>
		              <tr>
		                <td>deleted:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>addTime:</td>
		                <td>30480kgs</td>
		              </tr>
		              <tr>
		                <td></td>
		                <td>}</td>
		              </tr>
		              <tr>
		                <td>success:</td>
		                <td>true</td>
		              </tr>
		            </table>
		          </td>
		        </tr>
		
		        <tr>
		          <td>返回结果解释</td>
		          <td>templateInfo记录了模板的具体描述</td>
		        </tr>
		      </table>
		    </div>
		
		    <div class="content">
		      <h2><a name="p_href-4">4.海工平台模板修改接口</a></h2>
		      <!-- <h3><a name="p_href-4">3.1 接口地址</a></h3> -->
		      <table class="table">
		        <tr>
		          <td>内容</td>
		          <td>用于对模板内容的修改</td>
		        </tr>
		        <tr>
		          <td>URL</td>
		          <td>{{baseURL}}/codia/template/updateTemplate</td>
		        </tr>
		        <tr>
		          <td>提交方式(Method)</td>
		          <td>Post</td>
		        </tr>
		        <tr>
		          <td>重要字段解释</td>
		          <td>
		            {id}代表要修改的模板id，{templateTitle}是模板的标题，{templateDescribe}代表模板的具体描述，{templateName}是模板类型名称,该字段也对应着模板的类型名称物料上传的contentType字段，{templateInfo}则以json格式存储了模板的每个字段的字段名{field}和中文解释{name}
		          </td>
		        </tr>
		        <tr>
		          <td>http原始报文</td>
		          <td>
		            <p>POST /codia/template/updateTemplate</p>
		            <p>Host: 127.0.0.1:3000</p>
		            <p>Content-Type: application/json</p>
		            <p>Content-Length: 371</p>
		            {"id":"3081d510c98a4ad89a22ee5a6a837505","templateTitle":"测试模板11111111","templateDescribe":"测试模板的文11111字描述","templateName":"commonTemplate11","templateInfo":[{"index":1,"field":"matest","name":"测试1"},{"index":2,"field":"matest01","name":"测试2"}]}
		          </td>
		        </tr>
		        <tr>
		          <td>原始报文解释</td>
		          <td>
		            Content-Type为application/json,模板数据以json格式放在请求体中
		          </td>
		        </tr>
		
		        <tr>
		          <td>返回结果</td>
		          <td>
		            <table class="table">
		              <tr>
		                <td>errorCode:</td>
		                <td>200</td>
		              </tr>
		              <tr>
		                <td>message:</td>
		                <td>"修改成功"</td>
		              </tr>
		              <tr>
		                <td>data:</td>
		                <td>"null"</td>
		              </tr>
		              <tr>
		                <td>success:</td>
		                <td>"true"</td>
		              </tr>
		            </table>
		          </td>
		        </tr>
		      </table>
		    </div>
		
		    <div class="content">
		      <h2><a name="p_href-5">5.海工平台模板删除接口</a></h2>
		      <table class="table">
		        <tr>
		          <td>内容</td>
		          <td>可以通过id来对相应的模板进行删除操作</td>
		        </tr>
		        <tr>
		          <td>URL</td>
		          <td>{{baseURL}}/codia/template/deleteTemplate</td>
		        </tr>
		        <tr>
		          <td>提交方式</td>
		          <td>Post</td>
		        </tr>
		        <tr>
		          <td>重要字段解释</td>
		          <td>{id}代表要删除的模板对应的id，找不到id说明模板不存在</td>
		        </tr>
		        <tr>
		          <td>http原始报文</td>
		          <td>
		            <p>POST/codia/template/deleteTemplate</p>
		            <p>Host: 127.0.0.1:3000</p>
		            <p>Content-Type: application/json</p>
		            <p>Content-Length: 41</p>
		            {"id":"a5c020533d0f4527b45721c1c08b13e2"}
		          </td>
		        </tr>
		        <tr>
		          <td>原始报文解释</td>
		          <td>
		            1.Content-Type为application/json,模板数据以json格式放在请求体中.
		          </td>
		        </tr>
		
		        <tr>
		          <td>返回结果</td>
		          <td>
		            <table class="table">
		              <tr>
		                <td>errorCode:</td>
		                <td>500</td>
		              </tr>
		              <tr>
		                <td>message:</td>
		                <td>"该模板不存在"</td>
		              </tr>
		              <tr>
		                <td>data:</td>
		                <td>"null"</td>
		              </tr>
		              <tr>
		                <td>success:</td>
		                <td>"false"</td>
		              </tr>
		            </table>
		          </td>
		        </tr>
		      </table>
		    </div>
		
		    <div class="content">
		      <h2><a name="p_href-6">6.海工平台物料新增接口</a></h2>
		      <table class="table">
		        <tr>
		          <td>内容</td>
		          <td>
		            平台支持多种子系统上传物料信息，前提是要在已有的模板下完成添加，还能以json格式上传特有的数据
		          </td>
		        </tr>
		        <tr>
		          <td>URL</td>
		          <td>{{baseURL}}/codia/identity/addIdentity</td>
		        </tr>
		        <tr>
		          <td>提交方式(Method)</td>
		          <td>Post</td>
		        </tr>
		        <tr>
		          <td>重要字段解释</td>
		          <td>
		            {content}字段表示子系统物料特有信息，企业可以按json格式上传物料的特有信息
		          </td>
		        </tr>
		        <tr>
		          <td>http原始报文</td>
		          <td>
		            <p>POST /codia/identity/addIdentity</p>
		            <p>Host: 127.0.0.1:3000</p>
		            token: eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjIzODRhZTV
		            kMmI1NjRkNjNhODdhODA3NDVhMDI3NDIxIiwiZXhwIjoxNjMxMDk2Nzk2LCJpYX
		            QiOjE2MzEwOTMxOTZ9.mybvm6-cUDjLVSheRuOAVFDMvhyO8P9A0GhPKVpQvZk
		
		            <p>Content-Type: application/json</p>
		            <p>Content-Length: 1175</p>
		
		            {"materialId":"125136", "describe1":"物料描述",
		            "describe2":"物料描述2", "quality":"铁的", "specification":"规格",
		            "model":"型号", "unit":"基本单位", "materialType":"1",
		            "bigCategory":"大类", "midCategory":"中类",
		            "smallCategory":"小类", "longitude":"321.5648",
		            "latitude":"235.1513", "companyId":"企业id", "companyCode":"127",
		            "categoryCode":"123", "abc":"ABC标识", "mrpGroup":"MRP组",
		            "materialGroup":"物料组", "goodsGroup1":"商品组1",
		            "goodsGroup2":"商品组2", "materialStatus":"物料状态",
		            "available":true, "printed":true, "ext1":"扩展字段1",
		            "ext2":"扩展字段2", "handle1":"标识Handle1",
		            "handle2":"标识Handle2",
		            "content":"{\"TAG\":\"TGA1\",\"DeviceName\":\"保养设备1\",\"OpreateUser\":\"工作员1号\",\"OpreateTime\":\"2021-01-15
		            12:08:23\",\"DeviceNo\":\"515\",\"ItemDetail\":\"保养状态良好\",\"MaintenanceOperation\":\"第一阶段\",\"MaintenancePeriod\":\"7天\",\"LastMaintenanceTime\":\"2021-01-15
		            12:08:23\",\"NextMaintenanceTime\":\"2021-01-25
		            12:08:23\",\"NextMaintenanceWeek\":\"第8周\"}",
		            "contentType":"commonTemplate11", "addUser":"创建人",
		            "updateUser":"修改人", "remark":"备注"}
		          </td>
		        </tr>
		
		        <tr>
		          <td>原始报文解释</td>
		          <td>
		            1.请求头中添加token.
		            2.Content-Type为application/json,模板数据以json格式放在请求体中.
		          </td>
		        </tr>
		
		        <tr>
		          <td>返回结果</td>
		          <td>
		            <table class="table">
		              <tr>
		                <td>errorCode:</td>
		                <td>200</td>
		              </tr>
		              <tr>
		                <td>message:</td>
		                <td>"新增成功"</td>
		              </tr>
		              <tr>
		                <td>data:</td>
		                <td>"null"</td>
		              </tr>
		              <tr>
		                <td>success:</td>
		                <td>"true"</td>
		              </tr>
		            </table>
		          </td>
		        </tr>
		      </table>
		    </div>
		
		    <div class="content">
		      <h2><a name="p_href-7">7.海工平台物料查看接口</a></h2>
		      <table class="table">
		        <tr>
		          <td>内容</td>
		          <td>该接口不需要登录，现在仅能按照标识来查询物料</td>
		        </tr>
		        <tr>
		          <td>URL</td>
		          <td>{{baseURL}} /codia/identity/selectIdentity</td>
		        </tr>
		
		        <tr>
		          <td>提交方式(Method)</td>
		          <td>Get/Post</td>
		        </tr>
		        <tr>
		          <td>重要字段解释</td>
		          <td>
		            {handle1}是企业独有的标识，由前缀和IP地址拼接而成。示例：88.126.xxx/xxx.xxx.xxx
		          </td>
		        </tr>
		        <tr>
		          <td>http原始报文</td>
		          <td>
		            <p>GET /codia/identity/selectIdentity</p>
		            <p>Host: 127.0.0.1:3000</p>
		            <p>Content-Type: application/json</p>
		            <p>Content-Length: 41</p>
		            {"handle1":"88.126.127/001.123.12345136"}
		          </td>
		        </tr>
		        <tr>
		          <td>原始报文解释</td>
		          <td>
		            1.Content-Type为application/json,模板数据以json格式放在请求体中.
		          </td>
		        </tr>
		        <tr>
		          <td>返回结果</td>
		          <td>
		            <table class="table">
		              <tr>
		                <td>errorCode:</td>
		                <td>200</td>
		              </tr>
		
		              <tr>
		                <td>message:</td>
		                <td>"请求成功"</td>
		              </tr>
		
		              <tr>
		                <td>data:</td>
		                <td>{</td>
		              </tr>
		              <tr>
		                <td>id:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>materialId:</td>
		                <td>12345136</td>
		              </tr>
		              <tr>
		                <td>describe1:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>describe2:</td>
		                <td>admin</td>
		              </tr>
		              <tr>
		                <td>quality:</td>
		                <td>铁的</td>
		              </tr>
		              <tr>
		                <td>specification:</td>
		                <td>中码</td>
		              </tr>
		              <tr>
		                <td>model:</td>
		                <td>M78</td>
		              </tr>
		              <tr>
		                <td>unit:</td>
		                <td>件</td>
		              </tr>
		              <tr>
		                <td>materialType:</td>
		                <td>001</td>
		              </tr>
		              <tr>
		                <td>bigCategory:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>midCategory:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>smallCategory:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>longitude:</td>
		                <td>321.5648</td>
		              </tr>
		              <tr>
		                <td>latitude:</td>
		                <td>235.1513</td>
		              </tr>
		              <tr>
		                <td>companyId:</td>
		                <td>127</td>
		              </tr>
		              <tr>
		                <td>companyCode:</td>
		                <td>127</td>
		              </tr>
		              <tr>
		                <td>categoryCode:</td>
		                <td>123</td>
		              </tr>
		              <tr>
		                <td>abc:</td>
		                <td>abc</td>
		              </tr>
		              <tr>
		                <td>mrpGroup:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>materialGroup:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>goodsGroup1:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>goodsGroup2:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>materialStatus:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>contentType:</td>
		                <td>equipmentMaterialsSupplyChain</td>
		              </tr>
		              <tr>
		                <td>remark:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>available:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>printed:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>ext1:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>ext2:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>handle1:</td>
		                <td>88.126.127/001.123.12345136</td>
		              </tr>
		              <tr>
		                <td>handle2:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>content:</td>
		                <td>
		                  {\"TAG\": \"TGA1\", \"DeviceNo\": \"515\",
		                  \"DeviceName\":\"保养设备1\", \"ItemDetail\":
		                  \"保养状态良好\",\"OpreateTime\": \"2021-01-15 12:08:23\",
		                  \"OpreateUser\":\"工作员1号\", \"MaintenancePeriod\":
		                  \"7天\", \"LastMaintenanceTime\": \"2021-01-15 12:08:23\",
		                  \"NextMaintenanceTime\": \"2021-01-25 12:08:23\",
		                  \"NextMaintenanceWeek\": \"第8周\",
		                  \"MaintenanceOperation\": \"第一阶段\"}
		                </td>
		              </tr>
		              <tr>
		                <td>addUser:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>addTime:</td>
		                <td>2021-07-01 17:33:16</td>
		              </tr>
		              <tr>
		                <td>updateUser:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>updateTime:</td>
		                <td>2021-09-18 16:48:32</td>
		              </tr>
		              <tr>
		                <td>uploaded:</td>
		                <td>null</td>
		              </tr>
		              <tr>
		                <td>isDeleted:</td>
		                <td>false</td>
		              </tr>
		              <tr>
		                <td>deleted:</td>
		                <td>false</td>
		              </tr>
		              <tr>
		                <td></td>
		                <td>}</td>
		              </tr>
		
		              <tr>
		                <td>success</td>
		                <td>true</td>
		              </tr>
		            </table>
		          </td>
		        </tr>
		      </table>
		    </div>
		
		    <div class="content">
		      <h2><a name="p_href-8">8.海工平台物料删除接口</a></h2>
		      <table class="table">
		        <tr>
		          <td>内容</td>
		          <td></td>
		        </tr>
		        <tr>
		          <td>URL</td>
		          <td>{{baseURL}}/codia/identity/deleteIdentity</td>
		        </tr>
		        <tr>
		          <td>提交方式(Method)</td>
		          <td>Post</td>
		        </tr>
		        <tr>
		          <td>重要字段解释</td>
		          <td>
		            {handle1}是企业独有的标识，由前缀和IP地址拼接而成。示例：88.126.xxx/xxx.xxx.xxx
		          </td>
		        </tr>
		        <tr>
		          <td>http原始报文</td>
		          <td>
		            <p>POST /codia/identity/deleteIdentity</p>
		            <p>Host: 127.0.0.1:3000</p>
		            token: eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjIzODRhZTV
		            kMmI1NjRkNjNhODdhODA3NDVhMDI3NDIxIiwiZXhwIjoxNjMxMDk2Nzk2LCJpYX
		            QiOjE2MzEwOTMxOTZ9.mybvm6-cUDjLVSheRuOAVFDMvhyO8P9A0GhPKVpQvZk
		            <p>Content-Type: application/json</p>
		            <p>Content-Length: 41</p>
		            {"handle1":"88.126.127/001.123.12345136"}
		          </td>
		        </tr>
		        <tr>
		          <td>原始报文解释</td>
		          <td>
		            1.请求头中添加token.
		            2.Content-Type为application/json,模板数据以json格式放在请求体中.
		          </td>
		        </tr>
		
		        <tr>
		          <td>返回结果</td>
		          <td>
		            <table class="table">
		              <tr>
		                <td>errorCode:</td>
		                <td>200</td>
		              </tr>
		              <tr>
		                <td>message:</td>
		                <td>"删除成功"</td>
		              </tr>
		              <tr>
		                <td>data:</td>
		                <td>"null"</td>
		              </tr>
		              <tr>
		                <td>success:</td>
		                <td>"true"</td>
		              </tr>
		            </table>
		          </td>
		        </tr>
		      </table>
		    </div>
		    <a :href="`${path}pages/file/doc.xlsx`" download="海工平台文档.xlsx" name="p_href-9">
		      下载说明书 
		    </a>
		  </div>
		</div>
	</div>
</template>

<script>

export default {
  data() {
    //这里存放数据
    return {
      path: process.env.BASE_URL,
	  baseURL:'https://www.offshorecloud.org.cn'
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},

};
</script>
<style lang='scss' scoped>
	.openInterface_page_box{
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
	}
.openBox {
  width: $heightBaseContent;
  height: auto;
  box-sizing: border-box;
}

#nvaBox {
  position: fixed;
  top: 100px;
  float: left;
  width: 230px;

  padding: 0 15px;
  background-color: #00508f;
  color: #fff;
  border-radius: 15px;
  box-sizing: border-box;
}
#nvaBox li {
  display: block;
  width: 100%;
  line-height: 45px;
}
.nav_ul {
  width: 250px !important;
  padding: 0;
}
#nvaBox li a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 14px;
}
#nvaBox li a:hover {
  cursor: pointer;
  color: #fff;
}
.tabBox {
  float: right;
  width: calc(100% - 280px);
  margin-bottom: 30px;
}
.table,
.table tr {
  width: 100% !important;
}
.table tr td:nth-of-type(1) {
  width: 25% !important;
  text-align: center;
  color: #000;
  font-weight: bold;
}
.table tr td:nth-child(2) {
  width: 75% !important;
  text-align: left;
  color: #000;
}
.table td {
  position: relative;
  padding: 9px 15px;
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.content {
  width: 100%;
  margin: 20px 0;
}

.nav_li_child {
  font-size: 12px;
  margin: -20px 0;
}
.nav_li_child dd {
  margin: -20px 0;
  margin-left: 10px;
}
</style>

 
